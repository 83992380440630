import React from 'react';
import styled from 'styled-components';
import { transition } from '/style/mixin';

interface Props extends React.HTMLAttributes<HTMLElement> {
  backgroundColor?: string;
}
interface TypeCross {
  right?: boolean;
  backgroundColor?: string;
}
const Wrapper = styled.button`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 38px;
  height: 38px;
  padding: 0;
`;
const Cross = styled.div<TypeCross>`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -9px;
  margin-left: -1px;
  width: 2px;
  height: 18px;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  transform: ${props => (props.right ? 'rotate(45deg)' : 'rotate(-45deg)')};
  transform-origin: center center;
  ${transition('.15s all ease-in-out')}
`;

const Close: React.FC<Props> = ({ backgroundColor, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Cross backgroundColor={backgroundColor} />
      <Cross backgroundColor={backgroundColor} right />
      <span className="sr-only">Close</span>
    </Wrapper>
  );
};

export default Close;
