import styled from 'styled-components';
import FooterText from './FooterText';
import theme from '/style/theme';

const Wrapper = styled.div`
  margin-top: 30px;
  @media ${theme.sm} {
    margin-top: 40px;
  }
  @media ${theme.lg} {
    position: absolute;
    top: 249px;
    right: 18px;
    margin-top: 0;
    width: 352px;
    ${props =>
      props.is_business_site &&
      `
      top: 73px;
    `}
  }
  .brand {
    display: block;
    margin: 0 0 13px;
    svg {
      width: 84px;
      height: 26px;
    }
  }
`;

const CustomerCenter = ({ subdomain }) => {
  return (
    <Wrapper is_business_site={subdomain === 'business'}>
      <span
        className="brand"
        dangerouslySetInnerHTML={{
          __html: require('../../public/images/footer/footer-brand.svg?include'),
        }}
      />
      <FooterText>
        Trazy Co., Ltd | CEO Jahyun Kang
        <br />
        Business Registration No. 220-88-48571
        <br />
        Tourism Business Registration No. 2016-000102
        <br />
        17F, 89 Seosomun-ro, Sunhwa-dong, Jung-gu, Seoul, South Korea
      </FooterText>
    </Wrapper>
  );
};

export default CustomerCenter;
