import React from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import { transition } from '/style/mixin';

interface WrapperProps extends React.HTMLAttributes<HTMLElement> {
  as?: any; // TODO - fix any type
  type?: string;
  disabled?: boolean;
  half?: boolean;
  reset?: boolean;
  mint?: boolean;
  font_size?: string;
  small?: boolean;
  medium?: boolean;
  medium_large?: boolean;
  large?: boolean;
  full?: boolean;
  width?: string;
  height?: string;
  ghost?: boolean;
  normal?: boolean;
  gray?: boolean;
  lightgray?: boolean;
  remove?: boolean;
  gradient?: boolean;
  gradient_mint?: boolean;
  facebook?: boolean;
  radius?: string;
  noRadius?: boolean;
  border?: boolean;
  light_gray?: boolean;
  post?: boolean;
  padding?: string;
  font_weight?: number;
  line_height?: string;
}
interface Props extends WrapperProps {
  span?: boolean;
  submit?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position:relative;
  display: inline-block;
  margin: 0;
  padding: 17px;
  ${props =>
    props.padding &&
    `
    padding: ${props.padding};
  `}
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  background-image: none;
  white-space: nowrap;
  font-size: 14px;
  font-weight: ${theme.bold};
  ${props =>
    props.font_weight &&
    `
    font-weight: ${props.font_weight};
  `}
  line-height: 1.2;
  ${props =>
    props.line_height &&
    `
    line-height: ${props.line_height};
  `}
  background-color: ${theme.brand};
  color: #fff;
  border-radius: 5px;
  /* ${transition('.15s all ease-in-out')} */
  border: 0;
  /* 상태 */
  &:hover,
  &:focus,
  &.focus {
    background-color: ${theme.brand_hover};
    text-decoration: none;
  }

  &:active,
  &.active {
  }
  &.btn_disabled,
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none;
  }
  ${props =>
    props.radius &&
    `
    border-radius: ${props.radius};
  `}
  ${props =>
    props.border &&
    `
    border: 1px solid ${theme.line_gy};
  `}
  ${props =>
    props.font_size &&
    `
    font-size: ${props.font_size};
  `}
  ${props =>
    props.small &&
    `
    padding: 5px 10px;
    box-shadow: none;
    font-size: 16px;
    line-height: 1.2;
  `}
  ${props =>
    props.medium &&
    `
    padding: 9px 15px;
    box-shadow: none;
    font-size: 15px;
    line-height: 18px;
  `}
  ${props =>
    props.medium_large &&
    `
    padding: 13px 15px 12px;
    font-size: 15px;
    line-height: 17px;
  `}
  ${props =>
    props.large &&
    `
    padding: 10px 20px;
    font-size: 18px;
    line-height: 1.55;
    @media ${theme.sm} {
      padding: 20px 40px;
      font-size: 22px;
    }
  `}

  ${props =>
    props.half &&
    `
    padding-left: 0;
    padding-right: 0;
    width: 50%;
  `}
  ${props =>
    props.full &&
    `
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  `}
  ${props =>
    props.width &&
    `
    padding-left: 0;
    padding-right: 0;
    width: ${props.width};
  `}
  ${props =>
    props.height &&
    `
    padding-top: 0;
    padding-bottom: 0;
    line-height: ${props.height};
    height: ${props.height};
  `}
  ${props =>
    props.ghost &&
    `color: #fff;
    background-color: transparent;
    border: solid 1px #fff;
    border-radius: 0;
    &:hover,
    &:focus,
    &.focus {
      background-color: ${theme.trazy_mint};
      border-color: ${theme.trazy_mint};
    }
  `}
  /* weight */
  ${props =>
    props.normal &&
    `
    font-weight: ${theme.normal}
  `}
  /* color */
  ${props =>
    props.gray &&
    `
    background-color: ${theme.mid_gy}
    &:hover,
    &:focus,
    &.focus {
      background-color: ${theme.mid_gy}
    }
  `}
  ${props =>
    props.light_gray &&
    `
    background-color: #b3b3b3;
    &:hover,
    &:focus,
    &.focus {
      background-color: #b3b3b3;
    }
  `}
  ${props =>
    props.reset &&
    `
    background-color: ${theme.light_gy2};
    color: ${theme.mid_gy};
    &:hover,
    &:focus,
    &.focus {
      background-color: ${theme.light_gy2};
      color: ${theme.mid_gy};
    }
  `}
  ${props =>
    props.remove &&
    `
    background-color: ${theme.light_gy2};
    color: ${theme.mid_gy};
    &:hover,
    &:focus,
    &.focus {
      background-color: ${theme.light_gy2};
      color: ${theme.mid_gy};
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${theme.line_gy};
        z-index: 1;
        border-radius: 5px;
        background-image: url(https://d2ifd8cfqxdvq1.cloudfront.net/images/search/v3/btn_close_ssgy.png);
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  `}
  ${props =>
    props.lightgray &&
    `
    background-color: ${theme.light_gy3}
    &:hover,
    &:focus,
    &.focus {
      background-color: ${theme.light_gy3}
    }
  `}
  ${props =>
    props.gradient &&
    `
    background: linear-gradient(to right,${theme.light_og}, ${theme.pastel_og});
    &:hover,
    &:focus,
    &.focus {
      background: linear-gradient(to right, #f16647, #f05d3c);
    }
    :focus,
    &.focus {
      border-color: #d84315;
      box-shadow: inset 0 1px 2px 0 #d84315;
    }
  `}
  ${props =>
    props.gradient_mint &&
    `
    background-color: ${theme.trazy_mint};
    background: linear-gradient(to right, ${theme.light_mint3}, ${theme.trazy_mint});
    &:hover,
    &:focus,
    &.focus {
      background: linear-gradient(to right,  ${theme.trazy_mint}, #79d0d0 );
    }
    &:focus,
    &.focus {
      border-color: ${theme.trazy_mint};
      box-shadow: inset 0 1px 2px 0 ${theme.dark_mint};
    }
    `}
  ${props =>
    props.facebook &&
    `
    background-color: ${theme.fb}
    &:hover,
    &:focus,
    &.focus {
      background-color: ${theme.fb_hover}
    }
  `}
  ${props =>
    props.mint &&
    `
    background-color: ${theme.trazy_mint}
    &:hover,
    &:focus,
    &.focus {
      background-color: ${theme.mint_hover}
    }
  `}
  ${props =>
    props.noRadius &&
    `
    border-radius: 0!important;
  `}
  ${props =>
    props.post &&
    `
    letter-spacing: -0.2px;
    position: relative;
    overflow: hidden;
    background-color: #a7dfdf;
    border: solid 1px ${theme.trazy_mint};
    color: ${theme.dark_mint};
    font-weight: ${theme.bold};
    padding: 13px 8px 13px 50px;
    &:before {
      content: "";
      display: block;
      width: 42px;
      height: 42px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #87d8d9;
      background-image: url(${require('/public/images/ui/ico-review.svg')});
      background-position: center center;
      background-repeat: no-repeat;
    }
    &:hover,
    &:focus,
    &.focus  {
      background-color: #a0d7d7;
      &:before {
        background-color: #80d1d2;
      }
    }
  `}

`;

const Button: React.FC<Props> = ({ span, submit, children, ...rest }) => {
  return (
    <Wrapper type={submit ? 'submit' : 'button'} as={span ? 'span' : 'button'} {...rest}>
      {children}
    </Wrapper>
  );
};

export default Button;
