import styled from 'styled-components';
import FooterTitle from './FooterTitle';
import theme from '/style/theme';

const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;
  overflow: hidden;
  display: none;
  @media ${theme.sm} {
    display: block;
  }
  @media ${theme.lg} {
    margin-top: 138px;
  }
`;

const Video = styled.div`
  margin-top: 15px;
  a {
    display: block;
    position: relative;
    background-image: url("https://d2ifd8cfqxdvq1.cloudfront.net/images/main/video_thumb_1912.jpg");
    width: 270px;
    height: 168px;
    &:before {
      content: "";
      background: rgba(0,0,0,.3);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
  .video-bg {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 45px;
    height: 45px;
    margin-top: -22px;
    margin-left: -22px;
    background-image: url(${require('/public/images/ui/ico-play-video.svg')});
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

const FooterVideo = () => {
  return (
    <Wrapper>
      <FooterTitle>TRAVEL CRAZY VIDEO</FooterTitle>
      <Video>
        <a
          href="https://www.youtube.com/user/GoTrazy"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="sr-only">travel crazy video</span>
          <span className="video-bg"></span>
        </a>
      </Video>
    </Wrapper>
  );
};

export default FooterVideo;
