import styled from 'styled-components';
import theme from '/style/theme';
import Button from './Button';
import Price from '/components/ui/Price';
import { connect } from 'react-redux';
import actions from '/store/actions';
import useTranslation from 'next-translate/useTranslation';

const Wrapper = styled.div`
  position: relative;
  .total-row {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    .row-inner {
      position: relative;
      overflow: hidden;
      padding: 0;
      margin-left: 10px;
      margin-right: 10px;
      .label {
        font-weight: ${theme.normal};
        float: left;
        line-height: 24px;
        font-size: 14px;
        margin-top: 0;
        margin-left: 10px;
      }
      .value {
        float: right;
        line-height: 24px;
        font-size: 15px;
        color: ${theme.brand};
        font-weight: ${theme.medi};
      }
    }
    &.original {
      .value {
        color: ${theme.mid_gy};
      }
    }
    &.selling {
      border-top: 0;
      padding-top: 0;
      .label {
        color: ${theme.dark_gy};
        margin-left: 0;
        font-weight: ${theme.bold};
      }
      .value {
        font-weight: ${theme.bold};
        font-size: 20px;
      }
      .button-wrap {
        position: static;
        margin-top: 10px;
        button {
          display: block;
          width: 100%;
          font-weight: ${theme.normal};
        }
      }
    }
  }
  @media ${theme.sm} {
    .total-row {
      .row-inner {
        padding-right: 160px;
        margin-right: 0;
        margin-left: 0;
      }
      &.selling {
        .label {
          line-height: 50px;
          font-size: 15px;
        }
        .value {
          line-height: 50px;
        }
        .button-wrap {
          position: absolute;
          bottom: 0;
          right: 0;
          button {
            width: 140px;
          }
        }
      }
    }
  }
  ${props =>
    props.is_reservation_page &&
    `
    .total-row {
      .row-inner {
        margin: 0;
        .label {
          font-size: 13px;
        }
        .value {
          font-weight: ${theme.normal};
        }
      }
      &.amount {
        .value {
          color: ${theme.mid_gy};
        }
      }
      &.discount {
        .value {
          font-weight: ${theme.bold};
        }
      }
      &.selling {
        padding-top: 15px;
        margin-top: 20px;
        border-top: 1px solid ${theme.line_gy};
        .button-wrap {
          margin-top: 15px;
          button {
            font-weight: ${theme.bold};
          }
        }
        .label {
          font-size: 15px;
          .description {
            margin-top: 5px;
            display: block;
            font-size: 12px;
            font-weight: ${theme.normal};
            line-height: 14px;
            color: ${theme.mid_gy};
          }
        }
        .value {
          font-size: 18px;
          font-weight: ${theme.bold};
        }
      }
    }
    @media ${theme.sm} {
      .total-row {
        margin-top: 5px;
        .row-inner {
          padding-right: 137px;
        }
        &.selling {
          padding-top: 10px;
          margin-top: 10px;
          .label {
            line-height: inherit;
          }
          .button-wrap {
            margin-top: 0;
            button {
              width: 117px;
            }
          }
        }
      }
    }
  `}
`;
const ReceiptTotalBusiness = ({
  sum_original,
  sum_discount,
  sum_result_convert,
  sum_result,
  currency,
  is_reservation_page,
  payment_type,
  sendToNextStep,
  business_discount_percent,
  business_non_discountable,
  business_non_discountable_krw,
  ...rest
}) => {
  const to_currency = currency.tr_currency;
  const { t } = useTranslation('common');
  return (
    <Wrapper {...rest} is_reservation_page={is_reservation_page}>
      {is_reservation_page ? (
        <>
          <div className="total-row original">
            <div className="row-inner">
              <div className="label">{t('booking:total')}</div>
              <div className="value notranslate">
                <Price
                  price={sum_original}
                  from_currency={to_currency}
                  to_currency={to_currency}
                  use_symbol
                  force_show
                />
              </div>
            </div>
          </div>
          <div className="total-row original">
            <div className="row-inner">
              <div className="label">{t('booking:non-discount')}</div>
              <div className="value notranslate">
                {to_currency === 'KRW' ? (
                  <Price
                    price={business_non_discountable_krw}
                    from_currency="KRW"
                    to_currency="KRW"
                    use_symbol
                    force_show
                  />
                ) : (
                  <Price
                    price={business_non_discountable}
                    from_currency="USD"
                    to_currency={to_currency}
                    use_symbol
                    force_show
                  />
                )}
              </div>
            </div>
          </div>
          <div className="total-row discount">
            <div className="row-inner">
              <div className="label">{t('booking:discount')}</div>
              <div className="value notranslate">- {business_discount_percent} %</div>
            </div>
          </div>
          <div className="total-row discount">
            <div className="row-inner">
              <div className="label">{t('booking:discount-amount')}</div>
              <div className="value notranslate">
                {sum_discount == 0 ? '- ' : '' }
                <Price price={sum_discount} from_currency={to_currency} to_currency={to_currency} force_show={true} use_symbol />
              </div>
            </div>
          </div>
        </>
      ) :
        sum_discount ? (
          <>
            <div className="total-row original">
              <div className="row-inner">
                <div className="label">{t('cart.total')}</div>
                <div className="value notranslate">
                  <Price
                    price={sum_original}
                    from_currency={to_currency}
                    to_currency={to_currency}
                    use_symbol
                    force_show
                  />
                </div>
              </div>
            </div>
            <div className="total-row discount">
              <div className="row-inner">
                <div className="label">{t('cart.discount')}</div>
                <div className="value notranslate">
                  <Price price={sum_discount} from_currency={to_currency} to_currency={to_currency} force_show={true} use_symbol />
                </div>
              </div>
            </div>
          </>
        ) : null
      }
      <div className="total-row selling">
        <div className="row-inner">
          <div className="label">
            {t('cart.total-amount')}
            {is_reservation_page ? (
              <span className="description">
                {payment_type === 'transfer' ? t('booking:payment-usd-krw') : t('booking:payment-usd')}
              </span>
            ) : null}
          </div>
          <div className="value notranslate">
            {is_reservation_page ? (
              <Price price={sum_result} from_currency={'USD'} to_currency={'USD'} use_symbol force_show />
            ) : (
              <Price price={sum_result} from_currency={to_currency} to_currency={to_currency} use_symbol force_show />
            )}
          </div>
        </div>
        <div className="button-wrap">
          <Button onClick={sendToNextStep} gradient>
            {t('cart.check-out')}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default connect(state => state, actions)(ReceiptTotalBusiness);
