import styled from "styled-components";
import theme from "/style/theme";

const Wrapper = styled.div`
  display: block;
  float: left;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 15px;
  border: 1px solid ${theme.line_gy};
  padding: 0;
  text-align: center;
  background-color: #fff;
  ${props =>
    props.small &&`
    width: 29px;
    height: 29px;
    line-height: 27px;
  `}
  ${props =>
    props.big &&`
    width: 50px;
    height: 50px;
    line-height: 48px;
  `}
  ${props =>
    props.limit &&`
    background-color: ${theme.light_gy2};
  `}
`;

const Counter = ({ limit, small, type, children, ...rest }) => {
  return (
    <Wrapper type={type} small={small} limit={limit} {...rest}>
      {children}
    </Wrapper>
  );
};

export default Counter;
