import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: ${props => props.theme.bold};
  color: #fff;
  line-height: 1.2;
`;

const FooterTitle = ({children, ...rest}) => {
  return (
    <Wrapper {...rest}>{children}</Wrapper>
  )
};

export default FooterTitle;
