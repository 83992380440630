// @ts-nocheck
import React from 'react';
import Select, { components } from 'react-select';
import theme from '/style/theme';
import classnames from 'classnames';
import styled from 'styled-components';

interface ModelCustomStyles {
  height?: string;
  custom_color?: string;
  error?: boolean;
  time_selector?: boolean;
  rating_selector?: boolean;
  location_selector?: boolean;
  inside_modal?: boolean;
  components?: any;
}
interface ModelSelectable {
  label: string;
  value: string | number;
}
interface ModelRatingOption {
  label: string;
  value: number;
}
interface Props extends ModelCustomStyles {
  onChange?: Function;
  options: ModelSelectable[];
  className?: string;
  placeholder: string;
}

const StarRating = styled.section`
  width: 38px;
  height: 38px;
  display: inline-block;
  vertical-align: top;
  background-image: url(${require('/public/images/spot/3-wish-og-star-hover.svg')});
`;

const Option = (props: ModelRatingOption) => {
  let star = [];
  for (let cur = 1; cur <= props.value; cur++) {
    star.push(<StarRating className="star"></StarRating>);
  }
  return <components.Option {...props}>{props.label !== '' ? props.label : star}</components.Option>;
};

const SingleValue = ({ children, ...props }) => {
  let star = [];
  for (let cur = 1; cur <= props.data.value; cur++) {
    star.push(<StarRating></StarRating>);
  }
  return <components.SingleValue {...props}>{props.data.value > 0 ? star : children}</components.SingleValue>;
};

const customStyles = ({
  height,
  custom_color,
  error,
  time_selector,
  rating_selector,
  location_selector,
  inside_modal,
}: ModelCustomStyles) => {
  const selecHeight = height ? height : '50px';
  const normal_color = error ? theme.trazy_og : theme.line_gy;
  const error_style = error
    ? {
        backgroundImage: `url(${require('/public/images/ui/ico-exclamation.svg')})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'calc(100% + 10px) center',
      }
    : {};
  const time_container_style = time_selector
    ? {
        display: 'inline-block',
        letterSpacing: '-0.2px',
        fontSize: '14px',
      }
    : {};
  const custom_indicators_container_style =
    time_selector || location_selector
      ? {
          display: 'none',
        }
      : {};
  const rating_dropdown_indicators_style =
    rating_selector
      ? {
          padding: '0 5px',
          height: 'initial',
          span: {
            height: 'initial',
          },
        }
      : {};
  const time_value_container_style = time_selector
    ? {
        minWidth: '44px',
        padding: '15px',
      }
    : {};
  const time_single_value_style = time_selector
    ? {
        margin: '0px',
      }
    : {};
  const time_option_style = state =>
    time_selector
      ? {
          fontSize: '14px',
          lineHeight: '1.64',
          letterSpacing: '-0.2px',
          padding: '12px 15px',
          borderBottom: '1px solid #eaeaea',
          backgroundColor: state.isSelected ? '#F06534' : '#FFF',
          color: state.isSelected ? '#FFF' : '#666666',
          '&:hover': {
            backgroundColor: state.isSelected ? '#F06534' : '#F9F9F9',
          },
        }
      : {};
  const custom_menu_list_style =
    time_selector || rating_selector
      ? {
          padding: '0px',
          borderRadius: '5px',
          boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
          width: time_selector ? '50px' : 'auto',
          height: time_selector ? '282px' : inside_modal ? '240px' : '384px',
          maxHeight: 'none',
        }
      : {};
  const rating_option_style = state =>
    rating_selector
      ? {
          fontSize: '14px',
          lineHeight: '38px',
          height: '48px',
          letterSpacing: '-0.2px',
          padding: '5px 15px 4px',
          borderBottom: '1px solid #eaeaea',
          backgroundColor: state.isSelected ? '#F06534' : '#FFF',
          color: state.isSelected ? '#FFF' : '#666666',
          '.star': {
            backgroundImage: state.isSelected
              ? `url(${require('/public/images/spot/3-wish-star-hover.svg')})`
              : `url(${require('/public/images/spot/3-wish-og-star-hover.svg')})`,
          },
          '&:hover': {
            backgroundColor: state.isSelected ? '#F06534' : '#F9F9F9',
          },
        }
      : {};
  const rating_single_value_style = rating_selector
    ? {
        fontSize: '14px',
        lineHeight: '38px',
        height: '38px',
        letterSpacing: '-0.2px',
        margin: '0',
      }
    : {};
  const rating_value_container_style = rating_selector
    ? {
        padding: '6px 15px',
      }
    : {};
  const modal_menu_portal_style = inside_modal
    ? {
        zIndex: 9999,
      }
    : {};
  const custom_menu_list_style_og = custom_color
    ? {
        padding: '0px',
        borderRadius: '5px',
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
      }
    : {};
  const custom_option_style_og = state =>
    custom_color
      ? {
          backgroundColor: state.isSelected ? custom_color : '#FFF',
          color: state.isSelected ? '#FFF' : '#666666',
          '&:hover': {
            backgroundColor: state.isSelected ? custom_color : '#F9F9F9',
          },
        }
      : {};
  return {
    control: base => ({
      ...base,
      height: selecHeight,
      minHeight: selecHeight,
      borderColor: normal_color,
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        borderColor: normal_color,
      },
    }),
    menu: base => ({
      ...base,
      zIndex: 2,
      borderRadius: '0 0 5px 5px',
      border: `1px solid ${theme.very_light_gy}`,
      borderTop: 0,
      margin: '-3px 0 0',
      boxShadow: 'none',
    }),
    container: base => ({
      ...base,
      height: selecHeight,
      minHeight: selecHeight,
      ...time_container_style,
    }),
    valueContainer: base => ({
      ...base,
      height: selecHeight,
      minHeight: selecHeight,
      padding: '0 15px',
      ...time_value_container_style,
      ...rating_value_container_style,
      ...error_style,
    }),
    singleValue: base => ({
      ...base,
      margin: 0,
      color: theme.mid_gy,
      ...time_single_value_style,
      ...rating_single_value_style,
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: base => ({
      ...base,
      ...custom_indicators_container_style,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: '0 5px 0 0',
      transition: '.2s all ease-out',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      height: '38px',
      span: {
        height: '38px',
      },
      svg: {
        '.chevron': {
          fill: error ? theme.brand : theme.gy,
        },
      },
      '&:hover': {
        svg: {
          '.chevron': {
            fill: error ? theme.brand : theme.gy,
          },
        },
      },
      ...rating_dropdown_indicators_style,
    }),
    placeholder: base => ({
      ...base,
      color: theme.gy,
    }),
    option: (base, state) => ({
      ...base,
      borderTop: `1px solid ${theme.line_gy}`,
      backgroundColor: state.isSelected ? theme.light_og : '#FFF',
      color: state.isSelected ? '#FFF' : theme.mid_gy,
      transition: '.2s all ease-out',
      cursor: 'pointer',
      lineHeight: '23px',
      padding: '12px 15px',
      '&:hover': {
        backgroundColor: state.isSelected ? theme.light_og : theme.light_gy1,
      },
      ...time_option_style(state),
      ...rating_option_style(state),
      ...custom_option_style_og(state),
    }),
    menuList: base => ({
      ...base,
      ...custom_menu_list_style,
      ...custom_menu_list_style_og,
    }),
    menuPortal: base => ({
      ...base,
      ...modal_menu_portal_style,
    }),
  };
};
const DropdownIndicator = (
  // props: ElementConfig<typeof components.DropdownIndicator>
  props,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        dangerouslySetInnerHTML={{
          __html: require('../../public/images/ui/select-chevron.svg?include'),
        }}
      />
    </components.DropdownIndicator>
  );
};

const Selectbox: React.FC<Props> = ({
  options,
  height,
  className,
  custom_color,
  error,
  time_selector,
  rating_selector,
  location_selector,
  inside_modal,
  components,
  ...rest
}) => {
  return (
    <Select
      options={options}
      className={classnames('react-select-container', className)}
      components={rating_selector ? { Option, SingleValue } : { ...components, DropdownIndicator }}
      classNamePrefix="react-select"
      styles={customStyles({
        height,
        custom_color,
        error,
        time_selector,
        rating_selector,
        location_selector,
        inside_modal,
      })}
      menuPortalTarget={inside_modal ? document.body : null}
      {...rest}
    />
  );
};

export default Selectbox;
