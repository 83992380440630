// @ts-nocheck
import React, { useEffect } from "react";

const fetchImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = resolve;
    // image.onerror = reject;
  });
}

const useLazyBg = (target) => {
    useEffect(() => {
      if (!target.current) {
        return;
      }
      let observer = new window.IntersectionObserver(entries => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            const lazy_image = entry.target;
            await fetchImage(lazy_image.dataset.src)
            lazy_image.classList.add('on-screen');
            if (observer && observer.constructor == Object ) {
              observer.unobserve(lazy_image);
            }
          }
        });
      });
      if (observer) observer.observe(target.current);
      return () => {
        if (observer) {
          observer.disconnect();
          observer = null;
        }
      };
    }, [target]);
  }

  export default useLazyBg;
