/*@ts-nocheck*/
import { Component } from 'react';
import Header from './Header';
import HeaderMobile from './Header/Mobile';
import Footer from './Footer';
import styled from 'styled-components';
import SkipLinks from '/components/ui/SkipLinks';
import theme from '/style/theme';
import LoadingLayered from '/components/ui/LoadingLayered';
import SweetAlert from '/components/ui/SweetAlert';
import SweetAlertCustom from '/components/ui/SweetAlertCustom';
import { connect } from 'react-redux';
import actions from '/store/actions';
import ModalMember from '/components/ui/ModalMember';
import ModalSpot from '/components/Spot/ModalSpot';
import ModalPhoto from '/components/Spot/ModalPhoto';
import PhotoSwipe from '/components/ui/PhotoSwipe';
import { throttle } from 'lodash';
import ButtonTop from './ui/ButtonTop';

const Sitemain = styled.div`
  display: block;
  background-color: #fff;
  padding-bottom: 50px;
  ${props =>
    props.background_color &&
    `
    background-color:${props.background_color};
  `}
  ${props =>
    props.tablet_header_fixed &&
    `
    padding-top: 0;
    @media ${theme.sm} {
      padding-top: 60px;
    }
  `}
  ${props =>
    props.mobile_header_fixed &&
    `
    padding-top: 60px;
  `}
  ${props =>
    props.padding_bottom &&
    `
    padding-bottom:${props.padding_bottom};
  `}
  @media ${theme.sm} {
    padding-bottom: 100px;
    ${props =>
      props.padding_bottom &&
      `
      padding-bottom:${props.padding_bottom};
    `}
  }
  ${props =>
    props.padding_top &&
    `
    padding-top:${props.padding_top};
  `}
  @media ${theme.md} {
    padding-top: 60px;
    ${props =>
      props.padding_top &&
      `
      padding-top:${props.padding_top};
    `}
  }
`;
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        country_options: [],
        currency_options: [],
        is_login: 0,
        user: {},
      },
      loading: true,
      show_top_button: false,
    };
    this.onResize = throttle(this.onResize.bind(this), 50);
    this.onScroll = throttle(this.onScroll.bind(this), 50);
    this.xs_max = this.xs_max.bind(this);
    this.sm = this.sm.bind(this);
    this.sm_max = this.sm_max.bind(this);
    this.md = this.md.bind(this);
    this.md_max = this.md_max.bind(this);
  }
  xs_max() {
    const mq = window.matchMedia(theme.xs_max);
    return mq.matches;
  }
  sm() {
    const mq = window.matchMedia(theme.sm);
    return mq.matches;
  }
  sm_max() {
    const mq = window.matchMedia(theme.sm_max);
    return mq.matches;
  }
  md() {
    const mq = window.matchMedia(theme.md);
    return mq.matches;
  }
  md_max() {
    const mq = window.matchMedia(theme.md_max);
    return mq.matches;
  }
  onResize() {
    if (this.xs_max()) {
      // console.log('mobile');
      this.props.changeScreenWidth('xs');
    } else if (this.sm() && this.sm_max()) {
      // console.log('tablet');
      this.props.changeScreenWidth('sm');
    } else if (this.md() && this.md_max()) {
      // console.log('tablet');
      this.props.changeScreenWidth('md');
    } else {
      // console.log('desktop');
      this.props.changeScreenWidth('lg');
    }
  }
  onScroll() {
    if (window.pageYOffset > 300) {
      this.setState({ show_top_button: true });
    } else {
      this.setState({ show_top_button: false });
    }
  }
  async componentDidMount() {
    // run on first load
    this.onResize();
    this.onScroll();
    window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.onScroll);
    this.setState({ loading: false });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.onScroll);
  }
  render() {
    const { show_top_button, loading } = this.state;
    const {
      children,
      uistatus,
      sweetalert,
      padding_top,
      padding_bottom,
      mobile_header_gray,
      mobile_header_transparent,
      mobile_header_fixed,
      tablet_header_fixed,
      tablet_header_search,
      mobile_hide_top_button,
      background_color,
      hide_top_button,
      load_modal_spot,
      keyword,
      no_footer,
      show_organization,
      affiliate_partner,
      server,
    } = this.props;
    return (
      <>
        {!(server && server.is_crawler) && loading ? (
          <LoadingLayered show />
        ) : null}
        {show_organization ? (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(
                {
                  '@context': 'http://schema.org',
                  '@type': 'Organization',
                  name: 'Trazy',
                  url: 'https://www.trazy.com',
                  logo:
                    'https://d2ifd8cfqxdvq1.cloudfront.net/images/logo/squarelogo2.png',
                  sameAs: [
                    'https://www.facebook.com/gotrazy',
                    'https://www.instagram.com/trazy_korea',
                    'https://www.facebook.com/trazy.thailand',
                    'https://www.instagram.com/trazy_thailand',
                    'https://blog.trazy.com',
                    'https://www.youtube.com/user/GoTrazy',
                  ],
                  contactPoint: [
                    {
                      '@type': 'ContactPoint',
                      telephone: '+82-70-7008-3774',
                      email: 'inquiry@trazy.com',
                      contactType: 'customer service',
                      availableLanguage: ['English'],
                    },
                  ],
                },
                null,
                2
              ),
            }}
          />
        ) : null}
        <SkipLinks />
        {uistatus.screenwidth === 'sm' || uistatus.screenwidth === 'xs' ? (
          <HeaderMobile
            mobile_header_gray={mobile_header_gray}
            mobile_header_transparent={mobile_header_transparent}
            mobile_header_fixed={mobile_header_fixed}
            tablet_header_fixed={tablet_header_fixed}
            tablet_header_search={tablet_header_search}
            keyword={keyword}
            affiliate_partner={affiliate_partner}
          />
        ) : (
          <Header keyword={keyword} affiliate_partner={affiliate_partner} />
        )}
        <Sitemain
          className="site-main"
          id="site-main"
          mobile_header_fixed={mobile_header_fixed}
          tablet_header_fixed={tablet_header_fixed}
          padding_top={padding_top}
          padding_bottom={padding_bottom}
          background_color={background_color}
        >
          <section className="site-content">{children}</section>
        </Sitemain>
        {!no_footer ? <Footer /> : null}
        {/* global ui */}
        {sweetalert == 'custom' ? (
          <SweetAlertCustom data={uistatus.sweetalert} />
        ) : (
          <SweetAlert data={uistatus.sweetalert} />
        )}
        <ModalMember data={uistatus.modalmember} />
        {load_modal_spot ?
          <>
            <ModalSpot data={uistatus.modalspot} />
            <ModalPhoto data={uistatus.modalphoto} />
          </> : null}
        <LoadingLayered show={uistatus.loading} transparent />
        {uistatus.photoswipe && uistatus.photoswipe.show ? (
          <PhotoSwipe data={uistatus.photoswipe} />
        ) : null}
        <ButtonTop
          show={hide_top_button ? false : show_top_button}
          mobile_hide_top_button={mobile_hide_top_button}
        />
      </>
    );
  }
}

export default connect(state => state, actions)(Layout);
