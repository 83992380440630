import styled from 'styled-components';
import Link from 'next/link';
import { transition } from '/style/mixin';
import { connect } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';

const Wrapper = styled.div`
  position: absolute;
  z-index: 300;
  top: 49px;
  right: -12px;
  width: 184px;
  padding: 12px;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  li {
    margin: 0;
    padding: 0;
    span,
    a {
      cursor: pointer;
      display: block;
      padding: 10px;
      font-size: 15px;
      line-height: 1.2;
      letter-spacing: -0.2px;
      color: ${props => props.theme.mid_gy};
      ${transition()}
      &:hover {
        color: ${props => props.theme.brand};
      }
    }
  }
`;

const MypageMenu = ({
  logout, // from parent
  subdomain, // from store
}) => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>
      <ul className="menu">
        <li className="menu-item">
          <Link href="/mybooking">
            <a>{t('profile.my-bookings')}</a>
          </Link>
        </li>
        {subdomain === 'business' ? (
          <li className="menu-item">
            <Link href="/profile/settings">
              <a>{t('profile.business-profile')}</a>
            </Link>
          </li>
        ) : (
          <>
            <li className="menu-item">
              <Link href="/wishlist">
                <a>{t('profile.wishlist')}</a>
              </Link>
            </li>
            <li className="menu-item">
              <Link href="/profile/settings">
                <a>{t('profile.profile-setting')}</a>
              </Link>
            </li>
            <li className="menu-item">
              <Link href="/mypoint">
                <a>{t('profile.trazy-points')}</a>
              </Link>
            </li>
          </>
        )}

        <li className="menu-item">
          <Link href="/profile/settings/password">
            <a>{t('profile.password-setting')}</a>
          </Link>
        </li>
        {subdomain !== 'business' ? (
          <li className="menu-item">
            <Link href="/affiliate">
              <a>{t('profile.affiliate')}</a>
            </Link>
          </li>
        ) : null}
        <li className="menu-item">
          <span onClick={() => logout()}>{t('logout')}</span>
        </li>
      </ul>
    </Wrapper>
  );
};

export default connect(state => ({
  subdomain: state.subdomain,
}))(MypageMenu);
