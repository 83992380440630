import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import theme from '/style/theme';
import { throttle } from 'lodash';
const Wrapper = styled.div`
  .slick-slide {
    font-size: 0; /*remove inline-block margin*/
  }
  .slick-play-pause {
    @media ${theme.xs_max} {
      display: none;
    }
  }
  ${props =>
    props.mint &&
    `
    .slick-dots > li.slick-active button {
      background-color: ${theme.trazy_mint}
    }
  `}
  @media ${theme.sm_max} {
    .slick-list {
      overflow: inherit;
    }
  }
  ${props =>
    props.disable_desktop &&
    `
    @media ${theme.lg} {
      /* disable slider */
      border-top: 0;
      .slick-arrow,
      .slick-dots {
        display: none !important;
      }
      .slick-track,
      .slick-list {
        transform: none !important;
      }
      .slick-slider {
        width: auto !important;
      }
    }
  `}
  ${props =>
    props.transformX !== false &&
    `
    .slick-track {
      transform: translateX(${props.transformX}px) !important;
    }
    ${props.disable_desktop && `
    @media ${theme.lg} {
      .slick-track {
        transform: none !important;
      }
    }
    `}
  `}
`;
class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      play: true,
      slidePerPage: 1,
      activeSlide: 0,
      currentPage: 1,
      transformX: false,
      wrapperWidth: 0,
      listWidth: 0,
      slideWidth: 0,
      slideCount: 1,
      pageCount: 1,
      disableVariableWidth: false,
    };
    this.toggleStatus = this.toggleStatus.bind(this);
    this.updateSlider = throttle(this.updateSlider.bind(this), 500);
    this.sliderAfterChange = this.sliderAfterChange.bind(this);
    this.adjustTransform = this.adjustTransform.bind(this);
    this.adjustActiveSlide = this.adjustActiveSlide.bind(this);
    this.refSlider = React.createRef();
    this.refSliderWrapper = React.createRef();
  }
  toggleStatus() {
    if (this.refSlider.current) {
      if (this.state.play) {
        this.refSlider.current.slickPause();
      } else {
        this.refSlider.current.slickPlay();
      }
      this.setState(prev => ({
        play: !prev.play,
      }));
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateSlider);
    this.updateSlider();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSlider);
  }
  updateSlider() {
    const { dynamicItemPerPage } = this.props;
    if (!(dynamicItemPerPage && this.refSliderWrapper.current !== null)) return;
    const slider = this.refSliderWrapper.current;
    if (!(slider.children && slider.children[0])) return;
    const wrapper = slider.children[0];
    const wrapperWidth = wrapper.clientWidth;
    if (!(wrapper.children && wrapper.children[0])) return;
    const container =  wrapper.children[0].className.indexOf('slick-list') >= 0 ? wrapper.children[0] : wrapper.children[1];
    if (!(container.children && container.children[0])) return;
    const list = container.children[0];
    const listWidth = list.clientWidth;
    if (!(list.children && list.children[0])) return;
    let slideWidth = list.children[0].clientWidth;
    let disableVariableWidth = false;
    if (slideWidth > wrapperWidth && dynamicItemPerPage) {
      slideWidth = wrapperWidth;
      disableVariableWidth = true;
    }
    const slideCount = list.children.length;
    const slidePerPage = Math.floor(wrapperWidth / slideWidth) || 1;
    const pageCount = Math.ceil(slideCount / slidePerPage);
    this.setState(
      {
        wrapperWidth,
        listWidth,
        slideWidth,
        slideCount,
        slidePerPage,
        pageCount,
        disableVariableWidth,
      },
      this.adjustActiveSlide
    );
  }
  adjustActiveSlide() {
    const { dynamicItemPerPage } = this.props;
    if (dynamicItemPerPage) {
      const { slideWidth, pageCount, slidePerPage, activeSlide } = this.state;
      const currentPage = Math.floor(activeSlide / slidePerPage) + 1;
      const pageStartIdx = (currentPage - 1) * slidePerPage;
      this.setState({ currentPage: currentPage, transformX: false }, () => {
        if (currentPage == pageCount) {
          this.adjustTransform(pageStartIdx);
        } else if (activeSlide !== pageStartIdx) {
          this.setState({ transformX: pageStartIdx * slideWidth * -1 });
        }
      });
    }
  }
  adjustTransform(cur_idx) {
    const { dynamicItemPerPage } = this.props;
    if (dynamicItemPerPage) {
      const {
        wrapperWidth,
        listWidth,
        slideWidth,
        slideCount,
        pageCount,
        slidePerPage,
      } = this.state;
      const lastPageIdx = (pageCount - 1) * slidePerPage;
      if (
        pageCount > 1 &&
        cur_idx == lastPageIdx &&
        wrapperWidth >= slidePerPage * slideWidth
      ) {
        const delta = wrapperWidth - (slideCount % slidePerPage) * slideWidth;
        let transformX = pageCount * slidePerPage * slideWidth - delta;
        if (listWidth - transformX <= slideWidth * slidePerPage) {
          transformX = slideCount * slideWidth - wrapperWidth;
        }
        transformX = transformX * -1;
        this.setState({ transformX: transformX });
      } else {
        this.setState({ transformX: false });
      }
    }
  }
  sliderAfterChange(current, callback) {
    const { slidePerPage } = this.state;
    const currentPage = Math.floor(current / slidePerPage) + 1;
    this.setState({ activeSlide: current, currentPage: currentPage }, callback);
  }
  render() {
    const {
      mint,
      autoplay,
      onInit,
      children,
      noDots,
      noArrows,
      speed,
      fade,
      variableWidth,
      responsive,
      afterChange,
      centerMode,
      infinite,
      adaptiveHeight,
      rows,
      swipe,
      ...rest
    } = this.props;
    const { slidePerPage, transformX, disableVariableWidth } = this.state;
    // play/pause button position caculation
    // currently left: 50% is added from css
    // (((slider length * pager width) + additional active button width + button width) / -2) - play/pause button margin between pager;
    let play_pause_margin_left = 0;
    if (children && children.length > 1) {
      play_pause_margin_left = (children.length * 20 + 10 + 14) / -2 - 9;
    }
    const settings = {
      dots: !noDots,
      arrows: !noArrows,
      speed: speed ? speed : 500,
      fade: !!fade, // `fade` not working without conditional statement
      slidesToShow: slidePerPage,
      slidesToScroll: slidePerPage,
      autoplay,
      variableWidth: variableWidth && !disableVariableWidth,
      autoplaySpeed: 3000,
      infinite: infinite || !variableWidth ? true : false,
      responsive: responsive || [],
      afterChange: current => {
        this.sliderAfterChange(current, afterChange);
      },
      centerMode: !!centerMode,
      rows: rows || 1,
      adaptiveHeight,
      swipe: swipe || true,
      beforeChange: (current, next) => {
        this.adjustTransform(next);
      },
    };
    return (
      <Wrapper
        mint={mint}
        transformX={transformX}
        ref={this.refSliderWrapper}
        className={disableVariableWidth?'size-adjusted':''}
        {...rest}
      >
        <Slider {...settings} ref={this.refSlider} onInit={onInit}>
          {children}
        </Slider>
        {autoplay ? (
          <button
            className="slick-play-pause"
            style={{ marginLeft: play_pause_margin_left }}
            onClick={this.toggleStatus}
          >
            {this.state.play ? (
              <img
                src={require('/public/images/ui/ico-pause.png')}
                srcSet={`
                  ${require('/public/images/ui/ico-pause.png')} 1x,
                  ${require('/public/images/ui/ico-pause@2x.png')} 2x,
                  ${require('/public/images/ui/ico-pause@3x.png')} 3x
                  `}
                alt="pause"
              />
            ) : (
              <img
                src={require('/public/images/ui/ico-play.png')}
                srcSet={`
                  ${require('/public/images/ui/ico-play.png')} 1x,
                  ${require('/public/images/ui/ico-play@2x.png')} 2x,
                  ${require('/public/images/ui/ico-play@3x.png')} 3x
                  `}
                alt="play"
              />
            )}
          </button>
        ) : null}
      </Wrapper>
    );
  }
}

export default Carousel;
