import styled from 'styled-components';
import theme from '/style/theme';
import { clearfix } from '/style/mixin';

interface Props {
  verticalMiddle?: boolean;
  horizontalLabel?: boolean;
  horizontalLabelMobile?: boolean;
}

const Wrapper = styled.div<Props>`
  ${clearfix}
  line-height: auto;
  ${props =>
    props.horizontalLabel &&
    `
    @media ${theme.sm} {
        float: left;
        width: calc(100% - 125px);
        
    }
    `}
  ${props =>
    props.horizontalLabelMobile &&
    `
      float: left;
      width: calc(100% - 125px);
    `}
  ${props =>
    props.verticalMiddle &&
    `
    @media ${theme.lg} {
          float: none;
          padding-left: 125px;
          width: 100%;
    }
  `}
`;

const InputWrap: React.FC<Props> = ({ verticalMiddle, horizontalLabel, children, ...rest }) => {
  return (
    <Wrapper verticalMiddle={verticalMiddle} horizontalLabel={horizontalLabel} {...rest}>
      {children}
    </Wrapper>
  );
};

export default InputWrap;
