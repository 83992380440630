import React, {Component} from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import actions from '/store/actions';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Close from "/components/ui/Close";
import Button from "/components/ui/Button";
import { clearfix } from "/style/mixin";
import Textbox from "/components/ui/Textbox";
import getConfig from 'next/config';
import axios from "axios";
import withTranslation from 'next-translate/withTranslation';
import Trans from 'next-translate/Trans';
import Dropzone from 'react-dropzone';
const { publicRuntimeConfig } = getConfig();

const Wrapper = styled.div`
  min-height: 418px;
  background-color: #fff;
  position: relative;
  padding: 30px;
  .close-button{
    position: absolute;
    top:10px;
    right:10px;
  }
  .modal-title{
    font-size: 15px;
    font-weight: ${theme.bold};
    line-height: 1.53;
    letter-spacing: -0.2px;
    color: ${theme.dark_gy};
  }
  .modal-subtitle{
    font-size: 12px;
    letter-spacing: -0.2px;
    color: ${theme.mid_gy};
    margin: 5px 0 20px;
  }
  .upload-description {
    margin: 20px 0 30px;
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.2px;
    color: ${theme.mid_gy};
  }
  .btn-wrapper {
    float: right;
    .cancel-btn {
      background-color: ${theme.light_gy3};
      margin-right: 10px;
    }
  }
  .clearfix {
    ${clearfix}
  }
`;

const DropArea = styled.div`
  position: relative;
  margin: 0;
  padding: 5px;
  min-height: 150px;
  border-radius: 5px;
  border: solid 1px ${theme.light_og};
  background-color: ${theme.light_og1};
  text-align: center;
  color: ${theme.light_og};
  font-weight: ${theme.bold};
  outline: 0;
  cursor: pointer;
  input {
    outline: 0;
  }
  
  .dz-label-wrapper {
    margin: 15px 0;
    &.no-image {
      margin: 46px 0;
    }
    width: 100%;
    img {
      display: inline-block;
      vertical-align: top;
    }
    .dz-label {
      display: inline-block;
      line-height: 38px;
      color: ${theme.light_og};
      font-size: 15px;
      font-weight: ${theme.bold};
      letter-spacing: -0.2px;
      vertical-align: top;
    }
  }
  
  .preview-list {
    max-height: calc(100vh - 360px);
    overflow-y: auto;
    &::-webkit-scrollbar { display: none; }
    &.multi-image {
      text-align: left;
    }
    .dz-preview {
      cursor: default;
      display: inline-block;
      height: 160px;
      width: 255px;
      margin: 5px;
      .photo-area {
        background: #FFF;
        height: 130px;
        width: 100%;
        overflow: hidden;
        position: relative;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 48px;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
          position: absolute;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
`;

const Remove = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  text-indent: -9999px;
  display: block;
  width: 38px;
  height: 38px;
  background-position: -76px 0;
  background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/img_write_review.png');
  z-index: 1;
`;

class ModalPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.updateState = this.updateState.bind(this);
    this.removeFileByIndex = this.removeFileByIndex.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
  }
  updateState(name, values) {
    this.setState({
      [name]: values
    });
  }
  removeFileByIndex(index) {
    let _files = Object.assign([], this.state.files);
    _files.splice(index, 1);
    this.setState({
      files: _files,
    });
  }
  updateTitle(index, event) {
    const target = event.target;
    const value = target.value;
    let _files = Object.assign([], this.state.files);
    _files[index].title = value;
    this.setState({
      files: _files,
    });
  }
  submitUpdate() {
    const { authentication, changeSweetAlert, changeLoading, data } = this.props;
    const { initial_value, onUpdateSuccess } = data;
    const { spot_idx } = initial_value;
    const { files } = this.state;

    if (!authentication.user) {
      changeSweetAlert('Oops! Please login first.');
      return;
    }

    if (!files.length) {
      changeSweetAlert('Please select photo.');
      return;
    }

    changeLoading(true);
    const config = { headers: { Authorization: 'bearer ' + authentication.token } };
    let endpoint = `${publicRuntimeConfig.API_PATH}/spot/${spot_idx}/photo`;
    const params = new FormData();
    files.forEach((file) => {
      params.append('photo', file);
      params.append('title', file.title);
    });

    axios
      .post(
        endpoint,
        params,
        config
      )
      .then(res => {
        onUpdateSuccess(res.data);
      })
      .catch(err => {
        changeLoading(false);
        const response = err.response;
        if (response.data.detail) {
          const detail = response.data.detail;
          if (detail === 'Invalid authentication') {
            changeSweetAlert('Oops! Please login first.');
            return;
          } else {
            changeSweetAlert('Oops! Upload photo failed.\nPlease wait a moment and try again.');
            return;
          }
        } else {
          changeSweetAlert('Oops! Upload photo failed.\nPlease wait a moment and try again.');
          return;
        }
      });
  }
  componentDidMount() {}
  render() {
    const { data, changeModalPhoto, changeSweetAlert } = this.props;
    const { files } = this.state;
    const { t } = this.props.i18n;
    return (
      <Modal
        isOpen={data.show}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            outline: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflowX: 'hidden',
            padding: '0px',
            backgroundColor: 'transparent',
            border: 0,
            maxWidth: files && files.length > 1 ? 602 : 580,
            width: '100%',
            borderRadius: '5px',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
            overflow: 'visible'
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 200,
          },
        }}
      >
        <Wrapper>
          <Close
            className="close-button"
            backgroundColor={theme.darker_gray}
            onClick={() => {
              changeModalPhoto({
                show: false,
              });
            }}
          />
          <div className="modal-title">{t('add-more-photo')}</div>
          <div className="modal-subtitle">{t('up-to-max')}</div>
          <Dropzone
            accept="image/png, image/jpeg"
            maxSize={5242880}
            onDrop={acceptedFiles => {
              acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
                title: '',
              }));
              const new_files = files.concat(acceptedFiles);
              if (new_files.length > 8) {
                changeSweetAlert(
                  'Failed to upload files! You can upload up to 8 files'
                );
                return;
              }
              this.setState({files: new_files});
            }}
            onDropRejected={(res) => {
              if (res && res.length) {
                const over_max_size = res.some(v => v.size > 5242880);
                if (over_max_size)
                  changeSweetAlert('Oops! Please add a pic less than 5MB.');
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
                <DropArea {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  {files && files.length ?
                    <div className={`preview-list ${(files && files.length > 1) ? 'multi-image' : ''}`}>
                      {files.map((file, idx) => {
                        return <div className="dz-preview" key={`photo-${idx}`} {...getRootProps({ onClick: event => event.stopPropagation()})} tabIndex={-1}>
                          <div className="photo-area">
                            <Remove onClick={() => this.removeFileByIndex(idx)} />
                            <img src={file.preview} />
                          </div>
                          <Textbox
                            tabIndex={idx+1}
                            className="photo-description"
                            type="text"
                            placeholder="Title"
                            style={{
                              borderTopRightRadius: 0,
                              borderTopLeftRadius: 0,
                              border: 0,
                              padding: '5px 15px',
                              fontSize: 14,
                              height: 30
                            }}
                            value={file.title}
                            maxLength={200}
                            onChange={(event) => this.updateTitle(idx, event)}
                          />
                        </div>
                      })}
                    </div>
                  : null }
                  <div className={`dz-label-wrapper ${!(files && files.length) ? 'no-image':''}`}>
                    <img src={require('/public/images/spot/photo.svg')} />
                    <div className="dz-label">
                      {t('click-or-drag-photo')}
                    </div>
                  </div>
                </DropArea>
              )}
          </Dropzone>
          <div className="upload-description">
            <Trans i18nKey="spot:photo-description" components={{ br: <br/> }}></Trans>
          </div>
          <div className="btn-wrapper">
            <Button className="cancel-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={() => {
              changeModalPhoto({
                show: false,
              });
            }}>{t('common:popup.cancel')}</Button>
            <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>{t('add')}</Button>
          </div>
          <div className="clearfix"></div>
        </Wrapper>
      </Modal>
    );
  }
}

export default connect(state => state, actions)(withTranslation(ModalPhoto, 'spot'));
