import React, { Component } from 'react';
import actions from '/store/actions';
import { connect } from 'react-redux';
import Select from "/components/ui/Select";
import withTranslation from "next-translate/withTranslation";

class StarRatingSelect extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  onChange() {
    this.props.onChange();
  }
  render() {
    const { t } = this.props.i18n;
    let rating_options = [{value:0,label:t('not-applicable')}];
    for (let r=1;r<=7;r++) {
      rating_options.push({value:r,label:""});
    }
    const {value, onChange, ...rest} = this.props;
    return (
      <Select
          {...rest}
          isSearchable={false}
          defaultValue={value ? rating_options[value] : rating_options[0]}
          value={rating_options[value]}
          options={rating_options}
          rating_selector={true}
          onChange={onChange}
        >
      </Select>
    );
  }
}

export default connect(state => state, actions)(withTranslation(StarRatingSelect, 'spot'));
