import styled from 'styled-components';
import theme from '/style/theme';
import { clearfix } from 'style/mixin';

const Wrapper = styled.div`
  ${clearfix}
  margin: 0 -5px;
  clear: both;
  ${props =>
    props.gutter > -1 &&
    `
    margin: ${props.gutter === 0 ? 0 : `0 -${props.gutter / 2}px`};
  `}
  ${props =>
    props.no_grid_on_mobile &&
    `
    @media ${theme.xs_max} {
      margin: 0;
    }
  `}
`;

const Row = ({ children, ...rest }) => <Wrapper {...rest}>{children}</Wrapper>;

export default Row;
