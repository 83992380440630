import styled from "styled-components";
import { transition } from "/style/mixin";

const Wrapper = styled.span`
  display: inline-block;
  vertical-align: top;
  svg {
    background-color: transparent;
    vertical-align: top;
    .chevron {
      width: 38px;
      height: 38px;
      vertical-align: top;
      transform: ${props => (props.active ? "rotate(180deg)" : "rotate(0deg)")};
      ${props =>
        props.right &&
        `
        transform: rotate(-90deg);
      `}
      ${props =>
        props.left &&
        `
        transform: rotate(90deg);
      `}
      transform-origin: center center;
      fill: ${props => (props.active && props.active_color ? props.active_color : props.theme.gy)};
      ${props => props.white &&`
        fill: #fff;
      `}
      ${transition()}
    }
  }
`;

const Chevron = ({ ...rest }) => {
  return (
    <Wrapper {...rest}>
      <span dangerouslySetInnerHTML={{
        __html: require("../../public/images/ui/chevron.svg?include")
      }} />
    </Wrapper>
  );
};

export default Chevron;
