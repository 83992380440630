import styled from 'styled-components';
import Close from '/components/ui/Close';
import CityMenuStyle from '../CityMenuStyle';
import SearchBox from '../SearchBox';

const Wrapper = styled.div`
  position: fixed;
  z-index: 300;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.light_gy1};
  .search-header {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: ${props => props.theme.brand};
    text-align: center;
  }
  .search-wrap {
    position: absolute;
    z-index: 1;
    top: 50px;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }
  .close {
    margin-top: 6px;
    margin-right: 6px;
  }
`;
const ExploreByCity = styled.div`
  position: absolute;
  top: 110px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SearchBoxMobile = ({ keyword, close }) => {
  return (
    <Wrapper>
      <div className="search-header">
        <div className="search-title sr-only">Search</div>
        <Close onClick={close} className="close" />
      </div>
      <div className="search-wrap">
        <SearchBox keyword={keyword} />
      </div>
      <ExploreByCity>
        <CityMenuStyle className="city-menu" hide_other_countries={false} />
      </ExploreByCity>
    </Wrapper>
  );
};

export default SearchBoxMobile;
