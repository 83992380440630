import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 5px;
`;

const Caption = ({children, ...rest}) => {
  return (
    <Wrapper {...rest}>
      {children}
    </Wrapper>
  );
};

export default Caption;
