import styled from "styled-components";
import theme from "/style/theme";
import FooterTitle from "./FooterTitle";
import FooterText from "./FooterText";
import { singleOverflow } from "/style/mixin";

const Wrapper = styled.div`
  margin-top: 40px;
  display: none;
  @media ${theme.sm} {
    display: block;
  }
  @media ${theme.lg} {
    position: absolute;
    top: 0;
    margin-top: 0;
    left: 357px;
    width: 301px;
  }
  a {
    display: block;
    margin-top: 10px;
    ${singleOverflow}
  }
`;

const Blogs = ({data}) => {
  return (
    <Wrapper>
      <FooterTitle style={{'color': theme.brand, marginBottom: '15px'}}>
        BLOG UPDATES
      </FooterTitle>
      {data && data.length ?(
        data.map((v) => {
          return (
            <FooterText key={v.title}><a href={v.link} target="_blank" rel="noreferrer noopener" title={v.title}>{v.title}</a></FooterText>
          )
        })
      ) : null}
    </Wrapper>
  )
};

export default Blogs;
