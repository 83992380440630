import styled from "styled-components";
import Button from "/components/ui/Button";
import Link from "next/link";
import theme from "/style/theme";

const Wrapper = styled.div`
  margin-top: 30px;
  @media ${theme.sm} {
    margin-top: 40px;
  }
  button {
    display: block;
    margin-top: 10px;
  }
`;

const Tips = () => {
  return (
    <Wrapper>
      <Link href="/local-guide"><a><Button full ghost style={{fontSize : '15px'}}>BECOME A TRAZY LOCAL GUIDE/HOST</Button></a></Link>
      <Link href="/submit-tour"><a><Button full ghost style={{fontSize : '15px'}}>LIST YOUR TOUR/ACTIVITY</Button></a></Link>
    </Wrapper>
  )
};

export default Tips;
