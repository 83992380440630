import { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import Close from '/components/ui/Close';
import theme from '/style/theme';
import { connect } from 'react-redux';
import actions from '/store/actions';
import EmptyCart from '/components/ui/EmptyCart';
import getSum from '/utils/getSum';
import { Map } from 'immutable';
import getCaculatedQuantity from '/utils/getCaculatedQuantity';
import classnames from 'classnames';
import { debounce } from 'lodash';
import validateAndGetResult from '/utils/validateAndGetResult';
import LoadingLocal from '/components/ui/LoadingLocal';
import ReceiptTotalBusiness from '/components/ui/ReceiptTotalBusiness';
import Router from 'next/router';
import CustomScroll from 'react-custom-scroll';
import ElementCartItem from './ElementCartItem';
import parse from '/utils/parse';
import * as analytics from '/utils/analytics';
import withTranslation from 'next-translate/withTranslation';

const slideIn = keyframes`
  from {
    transform:translateX(600px);
  }
  to {
    transform:translateX(0);
  }
`;
const EmptyCartWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const Wrapper = styled.div`
  position: fixed;
  z-index: 300;
  top: 0;
  bottom: 0;
  right: 0;
  left: 20px;
  background-color: ${theme.light_gy1};
  @media (min-width: 620px) {
    width: 600px;
    left: auto;
  }
  animation: ${slideIn} 0.3s ease-out;
  .cart-header {
    background-color: #f3785b;
    height: 50px;
    padding: 15px 20px;
    position: relative;
    font-size: 18px;
    line-height: 1.2;
    color: #fff;
    font-weight: ${theme.bold};
  }
  .cart-content {
    position: absolute;
    top: 50px;
    bottom: 50px;
    left: 0;
    right: 0;
  }
  .partial-content {
    padding: 10px;
    @media ${theme.sm} {
      padding: 15px;
    }
  }
  .cart-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.is-empty {
    .cart-footer {
      background-color: #b3b3b3;
      background-image: linear-gradient(to right, ${theme.light_gy3} 0%, ${theme.light_gy4});
      margin: 20px;
      height: 50px;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      line-height: 50px;
      text-align: center;
      z-index: 5;
      font-size: 14px;
      font-weight: ${theme.medi};
    }
  }
  &.has-item {
    .cart-content {
      bottom: 120px;
      @media ${theme.sm} {
        bottom: 90px;
      }
    }
    .cart-footer {
      height: 90px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      padding: 15px 10px;
      background-color: #fff;
      height: auto;
      color: ${theme.mid_gy};
      @media ${theme.sm} {
        padding: 20px;
      }
    }
  }
  &.has-discount {
    .cart-content {
      bottom: 191px;
    }
    .cart-footer {
      height: 191px;
      .total-row {
        &.selling .row-inner {
          padding-top: 10px;
          border-top: 1px solid ${theme.line_gy};
        }
      }
    }
    @media ${theme.sm} {
      .cart-content {
        bottom: 168px;
      } 
      .cart-footer {
        height: 168px;
      }
    }
  }
  &.is-business {
    .cart-content {
      bottom: 137px;
      @media ${theme.sm} {
        bottom: 113px;
      }
      z-index: -1;
    }
    .note-for-business {
      white-space: nowrap;
      font-size: 10px;
      position: absolute;
      top: -31px;
      left: 0;
      right: 0;
      background: ${theme.very_light_gy};
      color: ${theme.mid_gy};
      z-index: 0;
      padding: 10px 15px;
    }
  }
  .warning-message {
    position: relative;
    margin-top: -1px;
    padding: 11px 11px 11px 38px;
    border-radius: 5px;
    line-height: 16px;
    color: ${theme.trazy_og};
    background-color: #fcddd6;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 38px;
      height: 38px;
      background-image: url('${require('/public/images/ui/ico-warning.svg')}');
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
`;

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart_for_db: null, // cart data for seding to backend
      cart_for_view: null, //  cart data for showing on front
      properties: '{}', //  user reservation info
      hash: '',
    };
    this.changeQuantity = this.changeQuantity.bind(this);
    this.getCartItemInfo = this.getCartItemInfo.bind(this);
    this.removeCartFront = this.removeCartFront.bind(this);
    this.sendToBookingPage = this.sendToBookingPage.bind(this);
    this.validateAndRemoveCart = this.validateAndRemoveCart.bind(this);
    this.saveCart = debounce(this.saveCart.bind(this), 500);
  }
  changeQuantity({ target, type, idx_arr }) {
    const { cart_for_view, cart_for_db } = this.state;
    let output_db = getCaculatedQuantity({
      target_name: 'optionOnCart',
      target_map: cart_for_db,
      target,
      type,
      idx_arr,
    });
    let output_view = getCaculatedQuantity({
      target_name: 'optionOnCart',
      target_map: cart_for_view,
      target,
      type,
      idx_arr,
    });
    const participants = output_db.getIn(idx_arr.slice(0, -1));
    const sum = participants.reduce((acc, val) => {
      return acc + val.get('qty');
    }, 0);
    if (sum === 0) {
      // remove from cart when participants sum is zero
      this.removeCartFront(idx_arr[0]);
    } else {
      this.setState({
        cart_for_db: output_db,
        cart_for_view: output_view,
      });
      this.saveCart(output_db);
    }
  }
  validateAndRemoveCart(key) {
    const { changeSweetAlert } = this.props;
    changeSweetAlert({
      show: true,
      title: 'Are you sure you want to delete this activity?',
      type: 'warning',
      showCancel: true,
      confirmBtnText: 'Yes',
      cancelBtnText: 'No',
      cancelBtnCssClass: 'sweetalert-cancel',
      onConfirm: () => {
        this.removeCartFront(key);
      },
    });
  }
  removeCartFront(key) {
    const { removeCart } = this.props;
    const { cart_for_db, cart_for_view } = this.state;
    removeCart(cart_for_db, key);
    const cart_for_db_deleted = cart_for_db.delete(key);
    this.setState({
      cart_for_view: cart_for_view.delete(key),
      cart_for_db: cart_for_db_deleted,
    });
    this.saveCart(cart_for_db_deleted);
  }
  async saveCart(cart) {
    const { updateCart, authentication, setCart } = this.props;
    const { lang } = this.props.i18n;
    const { hash, properties } = this.state;
    setCart(cart);
    if (authentication.user) {
      // if logged in
      await updateCart(authentication.token, cart, properties, hash, lang);
      window.safeStorage.removeItem('cart');
    } else {
      window.safeStorage.setItem('cart', JSON.stringify(cart.toJS()));
    }
  }
  async getCartItemInfo() {
    const { authentication, getCart } = this.props;
    const { lang } = this.props.i18n;
    //
    // 1. fetch basic cart data
    //
    let token = '';
    if (authentication.user) {
      token = authentication.token;
    }
    let cart_data = await getCart(token, lang);
    if (!cart_data) return;
    let { cart, cart_for_view, hash, properties } = cart_data;
    this.setState({ cart_for_view, cart_for_db: cart, hash, properties });
  }
  async sendToBookingPage() {
    const { cart, changeLoading, changeCartToggle, setReservation, changeSweetAlert, authentication } = this.props;
    let { cart_for_view, hash } = this.state;
    const { lang } = this.props.i18n;
    changeLoading(true);
    // validation
    const result = await validateAndGetResult({
      cart: cart_for_view,
      token: authentication.token,
      source: 'cart',
      locale: lang,
    });
    cart_for_view = result.cart;
    // if there's error more than one, it returns
    if (!result.is_passed) {
      changeLoading(false);
      changeSweetAlert({
        title: parse(
          'Oops! You cannot proceed to checkout with a sold-out item.<div class="description">1. Please go back to cart & delete it.\n2. Select another option, date, time or quantity & add again.</div>',
        ),
        show: true,
      });
      return;
    }
    setReservation(cart);

    let num_items = 0;
    let total_price = 0;
    const content_ids = [];
    if (cart_for_view) {
      if (cart_for_view.size) {
        num_items = cart_for_view.size;
        let { currency } = this.props;
        cart_for_view
          .entrySeq().forEach(([item_key, item_value]) => {
            // iterating data and date object
            let auto_discount = item_value.get('auto_discount');
            const v_map = Map({}).set(item_key, item_value);
            let item_sum = getSum({
              option_map: v_map,
              currency,
              use_symbol: false,
              use_format_money: false,
              auto_discount,
              custom_currency: 'USD',
            });
            /*
            let quantity = 0;
            if (item_value.get('participant_option')) {
              item_value.get('participant_option').valueSeq().forEach(x => {
                quantity += x.get('qty');
              });
            }
            */
            const activity_id = item_value.get('activity_id').toString();
            if (!content_ids.includes(activity_id)) {
              content_ids.push(activity_id);
            }
            total_price = total_price + item_sum.pre_converted;
          });
      }
    }
    analytics.initiateCheckout(content_ids, num_items, total_price, authentication);

    changeCartToggle(false);
    changeLoading(false);
    Router.push({ pathname: `/experience/book`, query: { hash } }).then(() => window.scrollTo(0, 0));
  }
  componentDidMount() {
    this.getCartItemInfo();
    document.body.classList.add('side-opened');
  }
  componentWillUnmount() {
    document.body.classList.remove('side-opened');
  }
  render() {
    let { currency, close, subdomain } = this.props;
    const { cart_for_view } = this.state;
    const is_business_site = subdomain === 'business';
    const { t } = this.props.i18n;

    let sum_original = 0;
    let sum_auto_discount = 0;
    let sum_result = 0;
    let has_item = false;
    let has_discount = false;
    let cart_items = [];
    if (cart_for_view) {
      if (cart_for_view.size) {
        has_item = true;
        cart_for_view
          .entrySeq()
          .sort((a, b) => {
            return (a[1].get('cart_order') || 0) - (b[1].get('cart_order') || 0);
          })
          .forEach(([item_key, item_value], i) => {
            // iterating data and date object
            let auto_discount = item_value.get('auto_discount');
            if (auto_discount) has_discount = true;
            if (is_business_site) {
              // no autodiscount for buisness
              has_discount = false;
              auto_discount = 0;
            }
            const limit = item_value.get('participant_limit');
            const v_map = Map({}).set(item_key, item_value);
            let discount = 0;
            let item_sum = getSum({
              option_map: v_map,
              currency,
              use_symbol: false,
              use_format_money: false,
              auto_discount,
            });
            const total_price_pre = item_sum.pre_converted;
            const total_price = item_sum.converted;
            discount = total_price - total_price_pre;
            sum_original += total_price_pre;
            sum_auto_discount += discount;
            sum_result += total_price;
            let error_message = null;
            if (item_value.get('validation') === 0) {
              const remaining = item_value.get('participant_limit') - item_value.get('n_reserve');
              if (remaining === 0) {
                error_message = `${t('cart.sold-out-error')}<br/>${t('cart.delete-reselect')}`;
              } else {
                let unit = item_value.get('participant_limit_unit');
                if (remaining > 1) {
                  unit += 's';
                }
                error_message = `${t('cart.remaining-error').replace('{{remaining}}', remaining).replace('{{unit}}', unit)}<br/>${t('cart.delete-reselect')}`;
              }
            }
            cart_items.push(
              <ElementCartItem
                key={`group${i}`}
                index={i}
                limit={limit}
                item_key={item_key}
                item_value={item_value}
                auto_discount={auto_discount}
                total_price_pre={total_price_pre}
                discount={discount}
                total_price={total_price}
                changeQuantity={this.changeQuantity}
                validateAndRemoveCart={this.validateAndRemoveCart}
                error_message={error_message}
              />,
            );
          });
      }
    }
    return (
      <Wrapper
        className={classnames('cart-wrap', {
          'has-item': has_item,
          'is-empty': !has_item,
          'has-discount': has_discount && subdomain !== 'business',
          'is-business': subdomain === 'business',
        })}
      >
        <div className="cart-header">
          {t('cart.my-cart')}
          <Close style={{ margin: '6px' }} onClick={close} />
        </div>
        {cart_for_view ? (
          cart_for_view.size ? (
            <>
              <div className="cart-content">
                <CustomScroll heightRelativeToParent="100%">
                  <div className="partial-content">{cart_items}</div>
                </CustomScroll>
              </div>
              <div className="cart-footer">
                {subdomain === 'business' ? (
                  <div className="note-for-business">{t('cart.business-discount')}</div>
                ) : null}
                <ReceiptTotalBusiness
                  sum_discount={sum_auto_discount}
                  sum_original={sum_original}
                  sum_result={sum_result}
                  sendToNextStep={this.sendToBookingPage}
                />
              </div>
            </>
          ) : (
            <>
              <div className="cart-content">
                <EmptyCartWrapper>
                  <EmptyCart />
                </EmptyCartWrapper>
              </div>
              <div className="cart-footer" onClick={close}>
                {t('cart.continue-browsing')}
              </div>
            </>
          )
        ) : (
          <div className="cart-content">
            <LoadingLocal show />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default connect(state => state, actions)(withTranslation(Cart, 'common'));
