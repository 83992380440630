import styled from 'styled-components';
import theme from '/style/theme';
import { transition } from '/style/mixin';

const Wrapper = styled.div`
  text-align: left;
  margin-top: 5px;
  font-size: 12px;
  color: ${theme.mid_gy};
  ${transition()}
  strong,
  a {
    color: ${theme.brand};
    font-weight: ${theme.normal};
    cursor: pointer;
    ${transition()}
  }
  &:hover {
    color: ${theme.light_gy4};
    strong,
    a {
      &:hover {
        color: ${theme.trazy_mint};
      }
    }
  }
`;
const Or = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Or;
