import { useState, useEffect } from 'react';
import Button from '/components/ui/Button';
import Textarea from '/components/ui/Textarea';
import InputGroup from '/components/ui/InputGroup';
import ButtonGroup from '/components/ui/ButtonGroup';
import { connect } from 'react-redux';
import actions from '/store/actions';
import Label from '/components/ui/Label';
import Rating from '/components/ui/Rating';
import Row from '/components/ui/Row';
import Col from '/components/ui/Col';
import axios from 'axios';
import getConfig from 'next/config';
import styled from 'styled-components';
import theme from '/style/theme';
import InputWrap from './InputWrap';
import CheckButton from './CheckButton';
import useTranslation from "next-translate/useTranslation";

const { publicRuntimeConfig } = getConfig();

const TextAreaWrapper = styled.div`
  position: relative;
`;

const TextCounter = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: ${theme.gy};
  font-size: 14px;
`;


const SpotReviewPopup = ({
  spot_id,
  // from store
  authentication,
  uistatus,
  changeSweetAlert,
  changeLoading,
  changeModalMember,
  onReviewSuccess,
}) => {
  const [rating, setRating] = useState(0);
  const [text, setText] = useState('');
  const { t } = useTranslation('spot');

  const enjoy_options_initial = [
    { label: t('family'), value: 'family', checked: false },
    { label: t('friends'), value: 'friends', checked: false },
    { label: t('partners'), value: 'partner', checked: false },
    { label: t('alone'), value: 'alone', checked: false },
  ];

  const [enjoy_options, setEnjoyOptions] = useState(enjoy_options_initial);

  const handleSubmit = event => {
    event.preventDefault();
    if (!authentication.user) {
      changeSweetAlert('Oops! Please login first.');
      return;
    }
    if (!rating) {
      changeSweetAlert('Please rate the destination.');
      return;
    }
    changeLoading(true);

    const config = {
      headers: { Authorization: 'bearer ' + authentication.token },
    };
    const enjoy = enjoy_options.map(cur => (cur.checked ? 1 : 0));
    const params = {
      text: text,
      rating: rating,
      enjoy: enjoy,
    };
    axios
      .post(
        `${publicRuntimeConfig.API_PATH}/spot/${spot_id}/review`,
        params,
        config
      )
      .then(() => {
        onReviewSuccess();
      })
      .catch(err => {
        changeLoading(false);
        const response = err.response;
        if (response) {
          if (response.data) {
            if (response.data.detail) {
              const detail = response.data.detail;
              if (detail === 'Invalid authentication') {
                changeSweetAlert('Oops! Please login first.');
                return;
              } else if (detail === 'Already left review') {
                changeSweetAlert(t('common:reviewed'));
                return;
              } else {
                changeSweetAlert(
                  t('common:review-failed')
                );
                return;
              }
            }
          }
        } else {
          changeSweetAlert(
            t('common:review-failed')
          );
          return;
        }
      });
  };

  const onCheckChange = (_, i) => {
    const updated_enjoy_options = [...enjoy_options];
    updated_enjoy_options[i] = {
      ...updated_enjoy_options[i],
      checked: !updated_enjoy_options[i].checked,
    };
    setEnjoyOptions(updated_enjoy_options);
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="form-login">
      <InputGroup style={{ marginBottom: '20px' }}>
        <Label htmlFor="rating">{t('rate-destination')}</Label>
        <Rating
          style={{
            position: 'absolute',
            top: '0',
            left: '160px',
          }}
          initialRating={rating}
          onChange={rate => setRating(rate)}
        />
      </InputGroup>
      <InputGroup style={{ marginBottom: '30px' }}>
        <Label htmlFor="enjoy" className="enjoy-label">
          {t('enjoyable-with')}
        </Label>
        <InputWrap className="enjoy-wrapper">
          {enjoy_options.map((v, i) => {
            return (
              <CheckButton
                name="enjoy"
                value={v.value}
                key={`enjoy${i}`}
                checked={enjoy_options[i].checked}
                onChange={e => onCheckChange(e, i)}
                small
                white
              >
                {v.label}
              </CheckButton>
            );
          })}
        </InputWrap>
      </InputGroup>
      <InputGroup style={{ marginBottom: '30px' }}>
        <Label htmlFor="text">
          {t('review-description')}
        </Label>
        <TextAreaWrapper>
          <Textarea
            id="text"
            name="text"
            value={text}
            onChange={e => setText(e.target.value)}
            className="textarea"
            style={{
              height: '140px',
            }}
            maxLength={1500}
          />
          <TextCounter>{text.length}/1500</TextCounter>
        </TextAreaWrapper>
      </InputGroup>
      <ButtonGroup>
        <Row style={{ textAlign: 'right' }}>
          <Col desktop_width="145px">
            <Button
              lightgray
              full
              onClick={() =>
                changeModalMember({
                  show: false,
                })
              }
            >
              {t('common:popup.cancel')}
            </Button>
          </Col>
          <Col desktop_width="130px">
            <Button submit disabled={uistatus.submitting} full>
              {t('common:popup.post')}
            </Button>
          </Col>
        </Row>
      </ButtonGroup>
    </form>
  );
};

export default connect(
  state => ({
    authentication: state.authentication,
    uistatus: state.uistatus,
  }),
  {
    changeSweetAlert: actions.changeSweetAlert,
    changeLoading: actions.changeLoading,
    changeModalMember: actions.changeModalMember,
  }
)(SpotReviewPopup);
