import styled from "styled-components";
import { transition } from "/style/mixin";
import Chevron from "./Chevron";

const Wrapper  = styled.button`
  height: 60px;
  padding: 0 10px 0 10px;
  font-size: 15px;
  line-height: 60px;
  font-weight: ${props => props.theme.medi};
  letter-spacing: -0.2px;
  color: ${props => props.active? props.theme.brand : props.theme.mid_gy};
  vetical-align: top;
  border-bottom: 2px solid transparent;
  border-color: ${props => props.active? props.theme.brand : 'transparent'};
  cursor: pointer;
  ${transition()}
`;

const ChevronButton = ({active, active_color, children, ...rest}) => {
  return (
    <Wrapper active={active} {...rest}>
      {children}
      <Chevron active={active} active_color={active_color} style={{marginTop: '12px'}} />
    </Wrapper>
  );
};

export default ChevronButton;
