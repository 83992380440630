import { Component } from 'react';
import styled from 'styled-components';
import { transition, resetList } from '/style/mixin';
import BgLayer from '/components/ui/BgLayer';
import Cart from './Cart';
import MypageMenu from './MypageMenu';
import theme from '/style/theme';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import actions from '/store/actions';
import Profile from '/components/ui/Profile';
import withTranslation from 'next-translate/withTranslation';
import Trans from 'next-translate/Trans';

const Wrapper = styled.div`
  margin-top: 11px;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  > .menu {
    ${resetList}
    margin: 0;
    > .menu-item {
      float: left;
      margin: 0;
      padding: 0;
      letter-spacing: -0.2px;
      text-align: center;
      margin-left: 20px;
      &:last-child {
        margin-left: 15px;
      }
      &:before {
        display: none;
      }
      &:first-child {
        margin-left: 0;
      }
      &.login-business {
        margin-right: 10px;
      }
      .download-app {
        ${transition()}
      }
      .download {
        display: none;
        @media (min-width: 1280px) {
          display: inline;
        }
      }
      .app {
        text-transform: capitalize;
        @media (min-width: 1280px) {
          text-transform: lowercase;
        }
      }
      .count {
        display: inline-block;
        position: absolute;
        top: 3px;
        right: 0;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        border-radius: 16px;
        background-color: ${theme.brand};
        color: #fff;
        font-size: 11px;
      }
      > a,
      > button {
        display: block;
        height: 49px;
        padding: 0 0 11px;
        font-size: inherit;
        font-size: 15px;
        line-height: 38px;
        font-weight: ${props => props.theme.medi};
        color: ${props => props.theme.mid_gy};
        ${transition()}
        cursor: pointer;
        letter-spacing: -0.2px;
        border-bottom: 2px solid transparent;
        &:hover {
          color: ${props => props.theme.brandsub};
        }
        svg {
          width: 38px;
          height: 38px;
        }
      }
    }
    .points {
      margin-top: 7px;
      font-size: 13px;
      letter-spacing: -0.08px;
      color: ${props => props.theme.mid_gy};
      strong {
        font-size: 15px;
        font-weight: ${props => props.theme.bold};
      }
    }
    .mypage {
      margin-left: 10px;
    }
  }
`;

class MemberStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mypage: false,
      bannerActive: false,
    };
    this.toggleState = this.toggleState.bind(this);
    this.toggleBannerActive = this.toggleBannerActive.bind(this);
    this.logoutWithClosing = this.logoutWithClosing.bind(this);
  }
  toggleState(stateName) {
    this.setState(prevState => ({
      [stateName]: !prevState[stateName],
    }));
  }
  toggleBannerActive() {
    this.props.toggleBanner();
    this.toggleState('bannerActive');
  }
  logoutWithClosing() {
    const { authentication, deauthenticate } = this.props;
    this.setState({ mypage: false });
    deauthenticate(authentication.token);
  }
  render() {
    const { bannerActive } = this.state;
    const { authentication, changeCartToggle, changeModalMember, uistatus, cart, subdomain, sum_point, device } = this.props;

    const cart_size = cart.size;
    const { t } = this.props.i18n;

    return (
      <Wrapper>
        <ul className="menu">
          {device !== 'app' && subdomain !== 'business' ?
            <li className="menu-item">
              <button
                onClick={this.toggleBannerActive}
                  style={
                    bannerActive
                      ? {
                          color: theme.brand,
                          borderColor: theme.brand,
                        }
                      : null
                  }
                className="download-app notranslate"
              >
                <Trans i18nKey="common:header.download-app" components={{
                  download: <span className="download"></span>,
                  app: <span className="app"></span>
                }}></Trans>
              </button>
            </li>
          : null }
          {authentication.user ? (
            <>
              {subdomain !== 'business' ? (
                <li className="menu-item points notranslate">
                  <strong>
                    <NumberFormat value={sum_point} displayType={'text'} thousandSeparator={true} prefix={''} />
                  </strong>{' '}
                  {t('header.trazy-point')}
                </li>
              ) : null}
              <li className="menu-item mypage">
                <Profile
                  src={authentication.user.profile_pic}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.toggleState('mypage')}
                />
                {this.state.mypage ? (
                  <>
                    <BgLayer onClick={() => this.toggleState('mypage')} transparent />
                    <MypageMenu
                      logout={() => {
                        this.logoutWithClosing();
                      }}
                    />
                  </>
                ) : null}
              </li>
            </>
          ) : (
            // 로그아웃 했을 때
            <>
              <li className={`menu-item ${subdomain === 'business' && !authentication.user?'login-business':''}`}>
                <button
                  onClick={() =>
                    changeModalMember({
                      show: true,
                      type: 'login',
                    })
                  }
                >
                  {t('login')}
                </button>
              </li>
              {subdomain !== 'business' ? (
                <li className="menu-item">
                  <button
                    onClick={() =>
                      changeModalMember({
                        show: true,
                        type: 'signup',
                      })
                    }
                  >
                    {t('signup')}
                  </button>
                </li>
              ) : null}
            </>
          )}
          {subdomain !== 'business' || (subdomain === 'business' && authentication.user) ? (
            <li className="menu-item mycart">
              <button
                dangerouslySetInnerHTML={{
                  __html: require('../../public/images/header/cart.svg?include'),
                }}
                onClick={() => {
                  changeCartToggle(true);
                }}
              />
              {cart_size ? <span className="count">{cart_size}</span> : null}
            </li>
          ) : null}
        </ul>
        {uistatus.opencart ? (
          <>
            <BgLayer onClick={() => changeCartToggle(false)} />
            <Cart close={() => changeCartToggle(false)} />
          </>
        ) : null}
      </Wrapper>
    );
  }
}

export default connect(
  state => ({
    uistatus: state.uistatus,
    authentication: state.authentication,
    cart: state.cart,
    subdomain: state.subdomain,
    sum_point: state.sum_point,
    device: state.device,
  }),
  {
    changeCartToggle: actions.changeCartToggle,
    changeModalMember: actions.changeModalMember,
    deauthenticate: actions.deauthenticate,
  },
)(withTranslation(MemberStatus, 'common'));
