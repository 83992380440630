import { useState } from 'react';
import styled from 'styled-components';
import Textbox from '/components/ui/Textbox';
import Button from '/components/ui/Button';
import theme from '/style/theme';
import axios from 'axios';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import { connect } from 'react-redux';
import actions from '/store/actions';
import checkEmailRule from '/utils/checkEmailRule';
import { placeholder } from '/style/mixin';

const Wrapper = styled.div`
  position: relative;
  display: none;
  @media ${theme.sm} {
    display: block;
  }
  @media ${theme.lg} {
    position: absolute;
    top: 0;
    right: 18px;
    width: 352px;
  }
  .newsletter-textbox {
    height: 43px;
    width: calc(100% - 84px);
    font-size: 13px;
    line-height: 17px;
    padding-top: 13px;
    padding-bottom: 13px;
    ${placeholder(theme.mid_gy)}
    &:focus {
      color: #555;
    }
  }
  .newsletter-submit {
    position: absolute;
    top: 0;
    right: 0;
    width: 84px;
    height: 43px;
    padding: 12px 0;
  }
`;
const Newsletter = ({ changeSweetAlert }) => {
  const [email, setValue] = useState('');
  const handleSubmit = e => {
    e.preventDefault();
    if (!email || !checkEmailRule(email)) {
      changeSweetAlert({
        show: true,
        title: 'Oops! Please write a proper email address.',
      });
      return;
    }
    axios({
      url: `${publicRuntimeConfig.API_PATH}/newsletter`,
      method: 'post',
      data: {
        email,
      },
    })
      .then(() => {
        changeSweetAlert({
          show: true,
          title: 'Hurray! Your e-mail has been registered.',
          type: 'success',
        });
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 409) {
            changeSweetAlert({
              show: true,
              title: 'Oops! This e-mail address has been already registered.',
            });
          }
        } else {
          changeSweetAlert({
            show: true,
            title: 'Unknow error occured.',
          });
        }
      });
  };
  return (
    <Wrapper>
      <form className="form-newsletter" noValidate onSubmit={handleSubmit}>
        <label htmlFor="ns_email">
          <span className="sr-only">Newsletter</span>
          <Textbox
            id="ns_email"
            name="ns_email"
            placeholder="Join Our Newsletter! (Enter Your Email)"
            noRadius
            className="newsletter-textbox"
            value={email}
            onChange={e => setValue(e.target.value)}
            autoComplete="off"
          />
        </label>
        <Button submit noRadius className="newsletter-submit">
          Subscribe
        </Button>
      </form>
    </Wrapper>
  );
};

export default connect(state => state, actions)(Newsletter);
