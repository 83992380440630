import { useRef } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import Link from 'next/link';
import { transition } from '/style/mixin';
import useLazyBg from '/utils/useLazyBg';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  position: relative;
  padding: 0 5px;
  ${props => props.small && `
    padding: 0 3px;
  `}
  a {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    display: block;
    width: 130px;
    height: 187px;
    ${props => props.small && `
      width: 90.5px;
      height: 130px;
    `}
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    opacity: 0;
    ${transition('.2s opacity ease-out')}
    &.on-screen {
      opacity: 1;
      background-image: url('${props => props.mobile_thumbnail_image}');
      @media ${theme.sm} {
        background-image: url('${props => props.thumbnail_image}');
      }
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
      opacity: 1;
      ${transition()}
    }
    &:hover:before {
      opacity: 0;
    }
  }
`;
const ItemTitle = styled.div`
  position: relative;
  z-index: 2;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: ${theme.bold};
  line-height: 23px;
  padding: 30px 10px 0;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  ${props => props.small && `
    font-size: 12px;
    line-height: 18px;
    padding: 30px 0 0;
  `}
`;

const CityItemMini = ({ data, uistatus, small }) => {
  const target = useRef(null);
  useLazyBg(target);
  return (
    <Wrapper
      mobile_thumbnail_image={data.mobile_thumbnail_image}
      thumbnail_image={data.thumbnail_image}
      small={small}
    >
      <Link href="/things-to-do/[slug]" as={`/things-to-do/${data.custom_url}`}>
        <a
          ref={target}
          data-src={
            uistatus.screenwidth === 'xs'
              ? data.mobile_thumbnail_image
              : data.thumbnail_image
          }
          title={`Top Things To Do in ${data.city_name}`}
        >
          <ItemTitle small={small}>{data.city_name}</ItemTitle>
        </a>
      </Link>
    </Wrapper>
  );
};

export default connect(state => ({
  uistatus: state.uistatus,
}))(CityItemMini);
