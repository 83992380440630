import styled from 'styled-components';
import Close from '/components/ui/Close';

const Wrapper = styled.div`
  position: fixed;
  z-index: 300;
  top: 10px;
  left: 50%;
  margin-left: -167px;
  width: 335px;
  text-align: center;
  .banner-wrap {
    position: relative;
    width: 355px;
    margin: 0 auto;
    > img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
`;
const Banner = ({ os, close }) => {
  let link =
    'https://itunes.apple.com/kr/app/trazy-travel-shop-for-asia/id1463754912?l=en&mt=8';
  if (os === 'Android') {
    link = 'https://play.google.com/store/apps/details?id=com.trazy.travel';
  }
  return (
    <Wrapper>
      <a
        href={link}
        rel="nofollow noreferrer"
        target="_blank"
        className="banner-wrap"
      >
        <img
          src={"https://d2ifd8cfqxdvq1.cloudfront.net/images/main/banner-190701-app-mobile%402x.png"}
          alt="Travel Crazy at your fingertips! Download Trazy App"
        />
      </a>
      <Close onClick={close} />
    </Wrapper>
  );
};

export default Banner;
