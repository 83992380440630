import styled from "styled-components";
import { transition } from "/style/mixin";
import theme from "/style/theme";

const Wrapper  = styled.button`
  height: 40px;
  width: 40px;
  display: block;
  float: left;
  vertical-align: top;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #eaeaea;
  text-indent: -9999px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #fff;
  ${props =>
    props.type === "subtract" &&
    `
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0;
    background-image: url('/images/ui/ico-minus.svg');
  `}
  ${props =>
    props.type === "add" &&
    `
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 0;
    background-image: url('/images/ui/ico-plus.svg');
  `}
  ${props =>
    props.small &&`
    width: 29px;
    height: 29px;
    font-size: 12px;
    border-radius: 0;
  `}
  ${props =>
    props.big &&`
    width: 50px;
    height: 50px;
    font-size: 12px;
  `}
  ${props =>
    props.limit &&`
    cursor: default;
    background-color: ${theme.light_gy2};
  `}
  
  ${transition()}
`;

const ButtonIncrement = ({small, limit, type, children, ...rest}) => {
  return (
    <Wrapper type={type} disabled={limit} small={small} limit={limit} {...rest}>
      {children}
    </Wrapper>
  );
};

export default ButtonIncrement;


// const Counter = styled.div`
// display: block;
// float: left;
// width: 40px;
// height: 40px;
// line-height: 40px;
// font-size: 15px;
// border: 1px solid ${theme.line_gy};
// padding: 0;
// text-align: center;
// `;
