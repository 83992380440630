import React from 'react';
import styled from 'styled-components';
import { transition, srOnly } from '/style/mixin';
import theme from '/style/theme';
// interface Props extends React.ChangeEvent<HTMLInputElement> {
interface Props extends React.InputHTMLAttributes<HTMLElement> {
  id?: string;
  small?: boolean;
  radio?: boolean;
  key_color?: string;
  init_color?: string;
  highlight?: boolean;
  block?: boolean;
}
interface WrapperProps {
  small?: boolean;
  key_color?: string;
  init_color?: string;
  highlight?: boolean;
  block?: boolean;
}
const Wrapper = styled.label<WrapperProps>`
  position: relative;
  padding-left: 23px;
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  ${props =>
    props.block && `
    display: block;
    margin-right: 0px;
  `}
  ${props =>
    props.small &&
    `
    padding-left: 18px;
    height: 13px;
  `}
  
  input[type='checkbox'], input[type='radio'] {
    ${srOnly}
  }
  .label {
    display: inline-block;
    ${props =>
    props.block && `
      display: block;
    `}
    vertical-align: top;
    font-size: 15px;
    font-weight: ${props => props.theme.normal};
    color: ${props => props.theme.mid_gy};
    line-height: 1.6;
    letter-spacing: -0.2px;
    ${transition()}
    ${props =>
      props.small &&
      `
        font-size: 12px;
        line-height: 1.2;
      `}
    ${props =>
      props.highlight &&
      `
        color: #ff6e59;
        &:after{
          content: "";
          position: absolute;
          display: block;
          background-color: #ffeeeb;
          border-radius: 5px;
          left: 22px;
          right: -2px;
          z-index: -1;
          top: 1px;
          bottom: 1px;
        }
      `}
    &:before {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      display: block;
      width: 13px;
      height: 13px;
      border-radius: 3px;
      border: 1px solid ${props => props.theme.light_gy3};
      background-color: #ffffff;
      ${transition()}
      ${props => props.small && `top: 0;`}
      ${props =>
        props.key_color &&
        `
        width: 14px;
        height: 14px;
        // background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/promotion/191023_th_finder/191023_th_finder_checkbox_all.png');
        border: 2px solid ${theme.gy};
        ${props.init_color && `border-color: ${props.init_color};`}
        background-color: #fff;
        top: 5px;
        transition: none;
      `}
    }
  }
  // active state
  input:checked + .label {
    ${props =>
      !props.init_color &&
      `
        color: ${props.theme.trazy_og};
     `}
    ${props =>
      props.key_color && !props.init_color &&
      `
        color: ${props.key_color}
     `}
    &:before {
      border-color: ${props => props.theme.trazy_og};
      background-color: ${props => props.theme.check_og};
      ${props =>
        props.key_color &&
        `
        // background-position: -14px 0;
        border: 0;
        background-color: ${props.key_color};
        `}
    }
  }
  // disabled state
  input[readonly] + .label,
  input[disabled] + .label {
    color: ${props => props.theme.gy};
    &:before {
      border-color: ${props => props.theme.light_gy3};
      background-color: ${props => props.theme.light_gy1};
    }
  }
`;

const Checkbox = ({
  key_color,
  init_color,
  radio,
  id,
  small,
  children,
  highlight,
  block,
  ...rest
}: Props) => {
  return (
    <Wrapper key_color={key_color} init_color={init_color} small={small} htmlFor={id} highlight={highlight} block={block}>
      <input type={radio ? 'radio' : 'checkbox'} id={id} {...rest} />
      <span className="label">{children}</span>
    </Wrapper>
  );
};

export default Checkbox;
