import styled from 'styled-components';
import theme from '/style/theme';
import { clearfix } from 'style/mixin';

const Wrapper = styled.div`
  ${clearfix}
  position: relative;
  padding: 0 5px;
  float: left;
  width: 50%;
  ${props =>
    props.gutter > -1 &&
    `
    padding: ${props.gutter === 0 ? 0 : `0 ${props.gutter / 2}px`};
  `}
  ${props =>
    props.vertical_margin &&
    `
    padding-top: ${props.gutter === 0 ? 0 : `${props.gutter / 2}px`};
    padding-bottom: ${props.gutter === 0 ? 0 : `${props.gutter / 2}px`};
  `}
  ${props =>
    props.column &&
    `
    width: ${100 / props.column}%;
  `}
  ${props =>
    props.column_md &&
    `
    @media ${theme.md_max} {
      width: ${100 / props.column_md}%;
    }
  `}
  ${props =>
    props.column_sm &&
    `
    @media ${theme.sm_max} {
      width: ${100 / props.column_sm}%;
    }
  `}
  ${props =>
    props.column_xs &&
    `
    @media ${theme.xs_max} {
      width: ${100 / props.column_xs}%;
      ${props =>
        props.column_xs == 1 &&
        `
        width: auto;
        float: none;
      `}
    }
  `}
  ${props =>
    props.margin_top_xs &&
    `
    @media ${theme.xs_max} {
      margin-top: ${props.margin_top_xs}px;
    }
  `}
  ${props =>
    props.desktop_width &&
    `
    @media ${theme.lg} {
      width: ${props.desktop_width};
      display: inline-block;
      float: none;
    }
  `}
  ${props =>
    props.margin &&
    `
    margin: ${props.margin};
  `}
  ${props =>
    props.no_grid_on_mobile &&
    `
    @media ${theme.xs_max} {
      width: auto;
      float: none;
      padding: 0;
    }
  `}
`;

const Col = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default Col;
