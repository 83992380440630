//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { resetList } from '/style/mixin';
import { connect } from 'react-redux';
import actions from '/store/actions';
import theme from '/style/theme';
import setLanguage from 'next-translate/setLanguage';
import { useRouter } from 'next/router';
import { setCookie } from '/utils/cookie';

const Wrapper = styled.div`
  @media ${theme.md} {
    position: absolute;
    z-index: 250;
    top: 60px;
    right: -10px;
    width: 150px;
    padding: 10px 20px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  .menu {
    ${resetList}
    .submenu {
      padding: 10px 0;
      @media ${theme.md} {
        padding: 0;
      }
      &-item {
        font-size: 14px;
        line-height: 38px;
        letter-spacing: -0.2px;
        color: ${theme.gy};
        white-space: nowrap;
        span {
          display: block;
          padding: 0 20px;
          @media ${theme.md} {
            padding: 0;
          }
          cursor: pointer;
          &.active {
            color: ${theme.brand};
            strong {
              color: ${theme.brand};
            }
          }
        }
        strong {
          display: inline-block;
          padding-left: 10px;
          margin-right: 10px;
          font-size: 15px;
          line-height: 38px;
          font-weight: ${theme.normal};
          color: ${theme.mid_gy};
        }
      }
    }
  }
`;
interface Props {}

const LanguageMenu: React.FC<Props> = ({
  toggleOpen,
  language,
}) => {
  const { locale } = useRouter();
  const cur_language = language?.data?.length ? language.data.find(x => x.locale == locale) : null;
  return (language && language.data ?
    <Wrapper>
      <ul className="menu">
        <li className="menu-item">
            <ul className="submenu">
              {language.data.map((z, i) => {
                  return (
                    <li className="submenu-item" key={`language${i}`}>
                      <span
                        className={
                          z.locale == cur_language?.locale
                            ? 'active'
                            : ''
                        }
                        onClick={() => {
                          if (locale !== z.locale) {
                            setLanguage(z.locale).then(r => {
                              setCookie('NEXT_LOCALE', z.locale);
                            });
                          }
                          toggleOpen(false);
                        }}
                      >
                        {z.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
        </li>
      </ul>
    </Wrapper>
  : null);
};

export default connect(state => state, actions)(LanguageMenu);
