import { Component } from 'react';
import styled, { keyframes } from 'styled-components';

const slideDown = keyframes`
  from {
    transform:translateY(-60px);
  }
  to {
    transform:translateY(0);
  }
`;
const Wrapper = styled.div`
  position: fixed;
  z-index: 199;
  top: 60px;
  left: 0;
  right: 0;
  text-align: center;
  animation: ${slideDown} 0.15s ease-out;
  .banner-wrap {
    position: relative;
    max-width: 1024px;
    margin: 0 auto;
    border-radius: 0 0 5px 5px;
    padding-left: 40px;
    background-image: linear-gradient(
      to left,
      #f06544,
      ${props => props.theme.light_og}
    );
    > img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
  .banner-buttons {
    position: absolute;
    left: 44%;
    top: 30%;
    width: 36.1328125%;
    a {
      float: left;
      width: 48.65%;
      margin-left: 2.7%;
      &:first-child {
        margin-left: 0;
      }
    }
    img {
      display: block;
      max-width: 100%;
    }
  }
`;
class Banner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Wrapper>
        <div className="banner-wrap">
          <img
            src={
              'https://d2ifd8cfqxdvq1.cloudfront.net/images/main/banner-app-main.png'
            }
            alt="Travel Crazy at your fingertips! Download Trazy App"
          />
          <div className="banner-buttons">
            <a
              href="https://itunes.apple.com/kr/app/trazy-travel-shop-for-asia/id1463754912?l=en&mt=8"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <img
                src={require('/public/images/header/app-apple.png')}
                srcSet={`
                ${require('/public/images/header/app-apple.png')} 1x,
                ${require('/public/images/header/app-apple@2x.png')} 2x,
                ${require('/public/images/header/app-apple@3x.png')} 3x
                `}
                alt="download on the app store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.trazy.travel"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <img
                src={require('/public/images/header/app-android.png')}
                srcSet={`
                ${require('/public/images/header/app-android.png')} 1x,
                ${require('/public/images/header/app-android@2x.png')} 2x,
                ${require('/public/images/header/app-android@3x.png')} 3x
                `}
                alt="get it on google play"
              />
            </a>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default Banner;
