import styled from 'styled-components';
import theme from '/style/theme';
import { transition } from '/style/mixin';
const Wrapper = styled.textarea`
  font-size: 14px;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  width: 100%;
  line-height: 20px;
  padding: 15px 16px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${theme.line_gy};
  color: ${theme.mid_gy};
  resize: none;
  outline: 0;
  ${transition(
    'padding 0s, color .15s ease-out, background-color .15s ease-out, box-shadow .15s ease-out, border .15s ease-out'
  )}
  &:focus {
    border: solid 1px ${theme.brand};
    color: #f05634;
    ${props =>
      props.mint &&
      `
        color: ${theme.trazy_mint};
        border: solid 1px ${theme.light_mint};
        box-shadow: 0 0 3px 0 ${theme.light_mint};
    `}
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${theme.light_gy3};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${theme.light_gy3};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${theme.light_gy3};
  }
  &:disabled,
  &:read-only {
    background-color: ${theme.light_gy1};
  }
`;
const Textarea = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default Textarea;
