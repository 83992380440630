import { Component } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import SearchBoxMobile from './SearchBoxMobile';
import MenuMobile from './MenuMobile';
import BannerMobile from './BannerMobile';
import BgLayer from '/components/ui/BgLayer';
import Cart from '../Cart';
import theme from '/style/theme';
import { connect } from 'react-redux';
import actions from '/store/actions';
import Container from '/components/Container';
import Router from 'next/router';
import { clearfix } from '/style/mixin';
import withTranslation from 'next-translate/withTranslation';
import Trans from 'next-translate/Trans';

const Siteheader = styled.header`
  height: 60px;
  width: 100%;
  padding-top: 17px;
  background-color: #fff;
  ${props =>
    props.transparent &&
    `
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    button {
      color: #fff;
      .bar { background-color: #fff; }
      svg g *{ stroke: #fff; }
    }
  `}
  ${props =>
    props.tablet_header_fixed &&
    `
    @media ${theme.sm} {
      background-color: ${theme.light_gy1};
      position:fixed;
      z-index: 200;
      top: 0;
      left: 0;
      right: 0;
      .em-header-fixed & {
        box-shadow: none;
        border-bottom: 1px solid ${theme.line_gy};
      }
    }
  `}
  ${props =>
    props.tablet_header_search &&
    `
    height: auto;
    @media ${theme.sm_max} {
      background-color: ${theme.light_gy1};
      ${DownloadApp} {
        color: ${theme.gy};
      }
      ${MobileButton} {
        .bar {
          background-color: ${theme.gy};
        }
        svg {
          g * {
            stroke: ${theme.gy};
          }
        }
      }
    }
  `}
  ${props =>
    props.mobile_header_fixed &&
    `
    background-color: ${theme.light_gy1};
    position:fixed;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
  `}
  ${props =>
    props.mobile_header_gray &&
    `
    background-color: ${theme.light_gy1};
  `}
`;
const Brand = styled.h1`
  margin: 0 auto;
  cursor: pointer;
  height: 26px;
  width: 84px;
`;
const DownloadApp = styled.button`
  position: absolute;
  left: 65px;
  top: 10px;
  height: 38px;
  padding: 0;
  font-size: 15px;
  line-height: 38px;
  font-weight: ${theme.bold};
  letter-spacing: -0.2px;
  color: ${theme.trazy_mint};
  cursor: pointer;
  .download {
    display: none;
    @media ${theme.sm} {
      display: inline;
    }
  }
  .app {
    text-transform: capitalize;
    @media ${theme.sm} {
      text-transform: lowercase;
    }
  }
`;
const MobileButton = styled.button`
  position: absolute;
  top: 11px;
  color: #fff;
  width: 38px;
  height: 38px;
  padding: 0;
  cursor: pointer;
  .bar {
    display: block;
    width: 20px;
    height: 2px;
    margin-left: 9px;
    margin-top: 6px;
    background-color: ${theme.trazy_mint};
    &:first-child {
      margin-top: 0;
    }
  }
  svg {
    g * {
      stroke: ${theme.trazy_mint};
    }
  }
  &.menu-button {
    left: 11px;
  }
  &.search-button {
    right: 55px;
  }
  &.cart-button {
    right: 11px;
    .count {
      position: absolute;
      right: 0px;
      top: 0px;
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      background-color: #f05634;
      border-radius: 16px;
      color: #fff;
      font-size: 11px;
      font-weight: 500;
      text-align: center;
    }
  }
`;
const SearchBar = styled.div`
  position: relative;
  height: 45px;
  line-height: 45px;
  margin-top: 20px;
  padding-left: 53px;
  padding-right: 10px;
  border: 1px solid ${theme.line_gy};
  box-shadow: none;
  font-size: 15px;
  color: ${theme.gy};
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  svg {
    position: absolute;
    top: 3px;
    left: 10px;
  }
`;
const SearchText = styled.span`
  color: ${theme.mid_gy};
`;
const BrandWithAffiliate = styled.div`
  ${clearfix}
  position: relative;
  text-align: center;
  margin-top: 3px;
  ${Brand} {
    display: inline-block;
    vertical-align: top;
    width: 64px;
    height: 20px;
  }
`;
const AffiliateWrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  padding-left: 10px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 1px;
    height: 20px;
    background-color: ${theme.light_gy3};
  }
`;
const TWayLogo = styled.div`
  width: 45px;
  height: 20px;
  background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/promotion/tway/tway_logo@2x.png');
  background-size: cover;
  display: inline-block;
  position: relative;
  vertical-align: top;
`;
class HeaderMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: false,
      cart: false,
      search: false,
      menu_mobile: false,
      os: 'Android',
    };
    this.toggleState = this.toggleState.bind(this);
    this.resetInteraction = this.resetInteraction.bind(this);
    this.getMobileOperatingSystem = this.getMobileOperatingSystem.bind(this);
  }
  toggleState(stateName) {
    this.setState(prevState => ({
      [stateName]: !prevState[stateName],
    }));
  }
  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'etc';
  }
  resetInteraction() {
    this.setState({
      banner: false,
      cart: false,
      search: false,
      menu_mobile: false,
    });
  }
  componentDidMount() {
    this.setState({ os: this.getMobileOperatingSystem() });
    Router.events.on('routeChangeStart', this.resetInteraction);
  }
  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.resetInteraction);
  }
  render() {
    const {
      changeCartToggle,
      mobile_header_gray,
      mobile_header_transparent,
      tablet_header_fixed,
      tablet_header_search,
      mobile_header_fixed,
      keyword,
      affiliate_partner,
      cart,
      uistatus,
      device,
      subdomain,
      authentication,
    } = this.props;
    const { search, banner, menu_mobile, os } = this.state;
    const { t } = this.props.i18n;
    return (
      <>
        <Siteheader
          transparent={mobile_header_transparent}
          mobile_header_fixed={mobile_header_fixed}
          mobile_header_gray={mobile_header_gray}
          tablet_header_fixed={tablet_header_fixed}
          tablet_header_search={tablet_header_search}
        >
          {affiliate_partner === 'tway' ? (
            <BrandWithAffiliate>
              <Brand>
                <Link href="/">
                  <a>
                    <img
                      src={require('../../../public/images/header/brand.png')}
                      srcSet={`
                            ${require('../../../public/images/header/brand.png')} 1x,
                            ${require('../../../public/images/header/brand@2x.png')} 2x,
                            ${require('../../../public/images/header/brand@3x.png')} 3x
                            `}
                      alt="Trazy"
                    />
                  </a>
                </Link>
                <div className="sr-only">Trazy - Book Travel Crazy Things to do in Asia</div>
              </Brand>
              <AffiliateWrapper>
                <TWayLogo />
              </AffiliateWrapper>
            </BrandWithAffiliate>
          ) : (
            <Brand>
              <Link href="/">
                <a>
                  <img
                    src={require('../../../public/images/header/brand.png')}
                    srcSet={`
                        ${require('../../../public/images/header/brand.png')} 1x,
                        ${require('../../../public/images/header/brand@2x.png')} 2x,
                        ${require('../../../public/images/header/brand@3x.png')} 3x
                        `}
                    alt="Trazy"
                  />
                </a>
              </Link>
              <div className="sr-only">Trazy - Book Travel Crazy Things to do in Asia</div>
            </Brand>
          )}
          <MobileButton
            className="menu-button"
            onClick={() => this.toggleState('menu_mobile')}
          >
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
            <div className="sr-only">Menu</div>
          </MobileButton>
          {device !== 'app' && subdomain !== 'business' ? (
            <DownloadApp
              onClick={() => this.toggleState('banner')}
              className="download-app notranslate"
            >
              <Trans i18nKey="common:header.download-app" components={{
                download: <span className="download"></span>,
                app: <span className="app"></span>
              }}></Trans>
            </DownloadApp>
          ) : null}
          {subdomain !== 'business' || (subdomain === 'business' && authentication.user) ?
            tablet_header_search ? (
              <Container>
                <SearchBar onClick={() => this.toggleState('search')}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: require('../../../public/images/header/search.svg?include'),
                    }}
                  />
                  {keyword ? (
                    <SearchText>{keyword}</SearchText>
                  ) : (
                    t('header.search-box-placeholder')
                  )}
                </SearchBar>
              </Container>
            ) : (
              <MobileButton
                className="search-button"
                onClick={() => this.toggleState('search')}
              >
                <div className="sr-only">Search</div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: require('../../../public/images/header/search.svg?include'),
                  }}
                />
              </MobileButton>
            )
          : null}
          {subdomain !== 'business' || (subdomain === 'business' && authentication.user) ?
            <MobileButton
              className="cart-button"
              onClick={() => changeCartToggle(true)}
            >
              <div className="sr-only">Cart</div>
              <span
                dangerouslySetInnerHTML={{
                  __html: require('../../../public/images/header/cart.svg?include'),
                }}
              />
              {cart.size ? <span className="count">{cart.size}</span> : null}
            </MobileButton>
          : null }
        </Siteheader>
        {uistatus.opencart ? (
          <>
            <BgLayer onClick={() => changeCartToggle(false)} />
            <Cart close={() => changeCartToggle(false)} />
          </>
        ) : null}
        {banner ? (
          <>
            <BgLayer onClick={() => this.toggleState('banner')} />
            <BannerMobile os={os} close={() => this.toggleState('banner')} />
          </>
        ) : null}
        {search ? (
          <SearchBoxMobile
            keyword={keyword}
            close={() => this.toggleState('search')}
          />
        ) : null}
        {menu_mobile ? (
          <>
            <BgLayer onClick={() => this.toggleState('menu_mobile')} />
            <MenuMobile close={() => this.toggleState('menu_mobile')} />
          </>
        ) : null}
      </>
    );
  }
}

export default connect(
  state => ({
    cart: state.cart,
    uistatus: state.uistatus,
    device: state.device,
    authentication: state.authentication,
    subdomain: state.subdomain,
  }),
  {
    changeCartToggle: actions.changeCartToggle,
  }
)(withTranslation(HeaderMobile, 'common'));
