import styled from 'styled-components';
import theme from '/style/theme';
import { clearfix } from '/style/mixin';
import Container from '/components/Container';
import CustomerCenter from './CustomerCenter';
import StayConnected from './StayConnected';
import Tips from './Tips';
import Blogs from './Blogs';
import FooterVideo from './FooterVideo';
import Newsletter from './Newsletter';
import Participation from './Participation';
import BusinessInfo from './BusinessInfo';
import MenuFooter from './MenuFooter';
import { connect } from 'react-redux';
import getConfig from 'next/config';

const Wrapper = styled.footer`
  padding: 50px 0;
  background-color: ${theme.dark_gy};
`;

const Row = styled.div`
  ${clearfix}
  margin-left: -10px;
  margin-right: -10px;
  @media ${theme.lg} {
    margin-left: -18px;
    margin-right: -18px;
  }
`;
const Column = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  @media ${theme.sm} {
    float: left;
    width: 50%;
  }
  @media ${theme.lg} {
    width: 337px;
    padding-left: 18px;
    padding-right: 18px;
  }
`;

const Footer = ({ subdomain, blog }) => {
  return (
    <Wrapper id="site-footer">
      <h2 className="sr-only">Colophon</h2>
      <Container>
        <Row>
          <Column>
            {/* column - 1 */}
            <CustomerCenter />
            <StayConnected />
            <Tips />
            {/* column - 2 */}
            <Blogs data={blog} />
          </Column>
          <Column>
            {/* column - 3 */}
            <Newsletter />
            <FooterVideo />
            {subdomain !== 'business' ? <Participation /> : null}
            <BusinessInfo subdomain={subdomain} />
            {/* bottom */}
          </Column>
        </Row>
        <MenuFooter />
        {/* - mixpanel */}
      </Container>
    </Wrapper>
  );
};

export default connect(state => ({
  subdomain: state.subdomain,
  blog: state.blog,
}))(Footer);
