import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import actions from '/store/actions';
import { connect } from 'react-redux';
import Button from "/components/ui/Button";
import Select from "/components/ui/Select";
import Radio from "/components/ui/Radio";
import classnames from "classnames";
import Textbox from "/components/ui/Textbox";
import withTranslation from "next-translate/withTranslation";

const Wrapper = styled.div`
  .day-range-selector {
    margin-top: 15px;
    label {
      .label {
        margin-right: 30px;
      }
      &:last-child{
        .label {
          margin-right: 0px;
        }
      }
    }
  }
  .day-selector{
    margin-top: 15px;
  }
  .time-selector{
    margin-top: 15px;
    .add-btn{
      vertical-align: top;
      margin-left: 30px;
    }
  }
  .opening-hours-wrapper{
    margin: 20px 0 15px;
    padding: 20px 0 13px;
    border-top: 1px solid ${theme.line_gy};
    border-bottom: 1px solid ${theme.line_gy};
    .opening-hours-inner-wrapper{
      .opening-hours-day{
        font-size: 15px;
        font-weight: ${theme.bold};
        line-height: 1.6;
        letter-spacing: -0.2px;
        color: ${theme.mid_gy};
        padding: 7px 0;
        width: 30px;
        margin-right: 20px;
        display: inline-block;
        vertical-align:top;
      }
      .opening-hours-list{
        width: calc(100% - 50px);
        display: inline-block;
        vertical-align:top;
        .opening-hours-item{
          display: inline-block;
          vertical-align:top;
          .opening-hours-item-time{
            display: inline-block;
            vertical-align:top;
            padding: 7px 0;
            font-size: 15px;
            line-height: 1.6;
            letter-spacing: -0.2px;
            color: ${theme.mid_gy};
          }
          .opening-hours-item-remove{
            display: inline-block;
            vertical-align:top;
            width:38px;
            height:38px;
            background-image: url(${require("/public/images/spot/btn-close-ssgy.svg")});
            cursor: pointer;
          }
        }
      }
    }
  }
  .inline-input{
    display: inline-block;
    width: calc(100% - 90px)
  }
  .guide-label{
    &.inline-label{
      width: 80px;
      display: inline-block;
      margin-right: 10px;
      line-height: 50px;
    }
  }
`;

const DaySelectorItem = styled.section`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid ${theme.line_gy};
  background-color: #FFFFFF;
  padding: 10px;
  font-size: 14px;
  color: ${theme.mid_gy};
  margin-right: 10px;
  cursor: pointer;
  &.active,&:hover {
    border-color: ${theme.trazy_og};
    background-color: ${theme.light_og2};
    color: ${theme.trazy_og};
  }
`;

class OpeningHoursSelect extends Component {
  constructor(props) {
    super(props);
    this.dayRangeToggle = this.dayRangeToggle.bind(this);
    this.dayToggle = this.dayToggle.bind(this);
    this.addOpeningHourItem = this.addOpeningHourItem.bind(this);
    this.removeOpeningHourItem = this.removeOpeningHourItem.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.updateState = this.updateState.bind(this);
  }
  componentDidMount() {}
  dayRangeToggle(event) {
    const { day_range_selected } = this.props;
    const target = event.target;
    let value = target.value;
    let day_selected = [false, false, false, false, false, false, false];
    const cur_value = day_range_selected;
    if (cur_value == value) {
      value = '';
    } else {
      if (value == "all") {
        day_selected = [true, true, true, true, true, true, true];
      } else if (value == "weekday") {
        day_selected = [true, true, true, true, true, false, false];
      } else if (value == "weekend") {
        day_selected = [false, false, false, false, false, true, true];
      }
    }
    this.updateState( 'day_range_selected', value);
    this.updateState( 'day_selected', day_selected);
  }
  dayToggle(index) {
    const { day_selected } = this.props;
    let update_day_selected = Object.assign([], day_selected);
    update_day_selected[index] = !update_day_selected[index];
    this.updateState( 'day_selected', update_day_selected);
  }
  addOpeningHourItem() {
    const { day_selected, open_hour, open_minute, close_hour, close_minute } = this.props;
    let { opening_hours } = this.props;
    const selected = day_selected.filter(active => active == true);
    if (selected.length > 0) {
      day_selected.forEach(function (cur, i) {
        if (cur) {
          opening_hours[i].push({
            open_hour: open_hour,
            open_minute: open_minute,
            close_hour: close_hour,
            close_minute: close_minute,
            label: open_hour + ' : ' + open_minute + ' ~ ' + close_hour + ' : ' + close_minute
          });
        }
      })
      this.updateState( 'day_range_selected', '');
      this.updateState( 'day_selected', [false, false, false, false, false, false]);
      this.updateState( 'open_hour', '00');
      this.updateState( 'open_minute', '00');
      this.updateState( 'close_hour', '00');
      this.updateState( 'close_minute', '00');
      this.updateState( 'opening_hours', opening_hours);
    } else {
      this.props.changeSweetAlert("Please select the day");
    }
  }
  removeOpeningHourItem(day, val) {
    const { opening_hours } = this.props;
    let update_opening_hours = Object.assign([], opening_hours);
    const index = update_opening_hours[day].indexOf(val);
    if (index >= 0) {
      update_opening_hours[day].splice(index, 1);
    }
    this.updateState('opening_hours', update_opening_hours);
  }
  handleInputChange(event) {
    this.props.onHandleInputChange(event);
  }
  handleSelectChange(name, e) {
    this.updateState(name, e.value);
  }
  updateState(name,values) {
    this.props.onUpdateState(name,values);
  }
  render() {
    const { t } = this.props.i18n;
    const day_options = t('day-options', {}, { returnObjects: true });
    let hour_options = [];
    for(let h=0;h<=23;h++) {
      const value = h.toString().padStart(2,'0');
      hour_options.push({value:value, label: value});
    }
    let minute_options = [];
    for(let m=0;m<=55;m+=5) {
      const value = m.toString().padStart(2,'0');
      minute_options.push({value:value, label: value});
    }
    const {day_range_selected, day_selected, open_hour, open_minute, close_hour, close_minute, closed_days, opening_hours, inside_modal} = this.props;
    return (
      <Wrapper>
        <div className="input-guide">{t('please-select-day')}</div>
        <div className="day-range-selector">
          <Radio value="all" name="day_range" onClick={this.dayRangeToggle} onChange={() => {return false;}}
                 checked={day_range_selected == "all"}>{t('all-day')}</Radio>
          <Radio value="weekday" name="day_range" onClick={this.dayRangeToggle} onChange={() => {return false;}}
                 checked={day_range_selected == "weekday"}>{t('weekday')}</Radio>
          <Radio value="weekend" name="day_range" onClick={this.dayRangeToggle} onChange={() => {return false;}}
                 checked={day_range_selected == "weekend"}>{t('weekend')}</Radio>
        </div>
        <div className="day-selector">
          {
            day_options.map((d,idx) => (
              <DaySelectorItem key={`day-selector-${idx}`} value={idx} className={classnames({active: day_selected[idx]})}
                           onClick={() => this.dayToggle(idx)}>{d}</DaySelectorItem>
            ))
          }
        </div>
        <div className="time-selector">
          <Select
            inside_modal={inside_modal}
            isSearchable={false}
            options={hour_options}
            defaultValue={hour_options[0]}
            value={{value: open_hour, label: open_hour}}
            time_selector={true}
            onChange={e =>
              this.handleSelectChange("open_hour", e)
            }
          ></Select>
          <span className="time-separator"> : </span>
          <Select
            inside_modal={inside_modal}
            isSearchable={false}
            options={minute_options}
            defaultValue={minute_options[0]}
            value={{value: open_minute, label: open_minute}}
            time_selector={true}
            onChange={e =>
              this.handleSelectChange("open_minute", e)
            }
          ></Select>
          <span className="time-separator">  ~  </span>
          <Select
            inside_modal={inside_modal}
            isSearchable={false}
            options={hour_options}
            defaultValue={hour_options[0]}
            value={{value: close_hour, label: close_hour}}
            time_selector={true}
            onChange={e =>
              this.handleSelectChange("close_hour", e)
            }
          ></Select>
          <span className="time-separator"> : </span>
          <Select
            inside_modal={inside_modal}
            isSearchable={false}
            options={minute_options}
            defaultValue={minute_options[0]}
            value={{value: close_minute, label: close_minute}}
            time_selector={true}
            onChange={e =>
              this.handleSelectChange("close_minute", e)
            }
          ></Select>
          <Button className="add-btn" font_size="15px" font_weight={500} padding="13px 30px"
                  line_height={1.6} onClick={this.addOpeningHourItem}>{t('add')}</Button>
        </div>
        <div className="opening-hours-wrapper">
          {
            day_options.map((d,idx) => (
              <div key={`opening-hours-inner-wrapper-${idx}`} className="opening-hours-inner-wrapper">
                <div className="opening-hours-day">{d}</div>
                <div className="opening-hours-list">
                  {
                    opening_hours[idx].map((v, j) => (
                      <div key={`opening-hours-item-${idx}-${j}`} className="opening-hours-item"><span
                        className="opening-hours-item-time">{v.label}</span><span
                        className="opening-hours-item-remove"
                        onClick={() => this.removeOpeningHourItem(idx, v)}></span></div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
        <div className={classnames("guide-label", { "inline-label": inside_modal})}>{t('closed-days')}</div>
        <div className={classnames( { "inline-input": inside_modal})}>
          <Textbox className="closed-days-input" type="text" name="closed_days" value={closed_days} onChange={this.handleInputChange}></Textbox>
        </div>
      </Wrapper>
    );
  }
}

export default connect(state => state, actions)(withTranslation(OpeningHoursSelect, 'spot'));
