import styled from 'styled-components';
import { resetList, clearfix } from '/style/mixin';
import theme from '/style/theme';
import CityMenu from './CityMenu';

const Wrapper = styled.nav`
  @media ${theme.sm_max} {
    .menu {
      ${resetList}
      background-color: ${theme.line_gy};
      &-item {
        border-top: 1px solid ${theme.line_gy};
        ${clearfix};
        &:first-child {
          border-top: 0;
        }
        .link-wrapper {
          > a,
          > span {
            position: relative;
            display: block;
            height: 50px;
            padding: 16px;
            font-size: 15px;
            font-weight: ${theme.bold};
            line-height: 1.2;
            color: ${theme.mid_gy};
            text-transform: uppercase;
            background-color: ${theme.light_gy1};
            border-bottom: 1px solid ${theme.line_gy};
          }
          > a {
            &:before {
              content: "";
              position: absolute;
              top: 6px;
              right: 10px;
              width: 38px;
              height: 38px;
              background: url(${require('/public/images/ui/chevron-right.svg')})
              no-repeat center center;
            }
          }
        }
        .other-countries-btn-wrapper {
          background-color: ${theme.light_gy1};
          height: 51px;
          padding: 10px;
          border-top: 1px solid ${theme.line_gy};
        }
      }
    }
    .submenu {
      background-color: #fff;
      ${clearfix}
      &-item {
        float: left;
        width: 50%;
        > a,
        > span {
          display: block;
          position: relative;
          height: 50px;
          margin-right: -1px;
          margin-bottom: -1px;
          padding: 16px 20px;
          font-size: 15px;
          line-height: 1.2;
          color: ${theme.mid_gy};
          border-right: 1px solid ${theme.line_gy};
          border-bottom: 1px solid ${theme.line_gy};
        }
        &.proxy-link {
          > a {
            color: ${theme.trazy_og};
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  @media ${theme.md} {
    overflow: hidden;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    .menu {
      ${resetList}
      &-item {
        position: relative;
        background-color: ${theme.light_gy1};
        border-top: 1px solid ${theme.line_gy};
        &:first-child {
          border-top: 0;
        }
        .link-wrapper {
          width: 158px;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -11px;
          text-align: center;
          > a,
          > span {
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.44;
            letter-spacing: -0.18px;
            text-align: center;
            color: ${theme.mid_gy};
            text-transform: uppercase;
          }
        }
        
        &.thumbnail-menu-item {
          padding: 10px 10px 35px 10px; 
          .link-wrapper {
            position: relative;
            margin-top: 0px;
            width: initial;
            text-align: left;
            top: initial;
            left: initial;
            > a {
              &:hover {
                color: ${theme.trazy_og};
              }
            }
          }
          .thumbnail-list {
            margin-top: 5px;
            margin-bottom: 12px;
            a {
              &:hover {
                text-decoration: none;
              }
            }
          }
          .other-countries-btn-wrapper {
            a {
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
      .submenu {
        margin: 0 0 0 158px;
        padding: 10px 0;
        background-color: #fff;
        overflow: hidden;
        &-item {
          float: none;
          display: inline-block;
          padding: 0;
          font-size: 16px;
          line-height: 1.2;
          font-weight: ${theme.normal};
          line-height: normal;
          letter-spacing: -0.2px;
          color: ${theme.mid_gy};
          a {
            display: block;
            padding: 10px 15px;
          }
          &.proxy-link {
            a {
              color: ${theme.trazy_og};
            }
          }
        }
      }
    }
  }
  ${props =>
    props.destinations &&
    // for home destinations popup
    `
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    .menu-item {
      > a,
      > span {
        color: ${theme.brand};
      }
    }
    @media ${theme.md} {
      position: static;
      width: auto;
    }
  `}
  ${props =>
    props.mybooking &&
    // for mybooking page
    `
    @media ${theme.md} {
      border-top: 1px solid ${theme.very_light_gy};
      border-bottom: 1px solid ${theme.very_light_gy};
      margin: 23px 0 10px;
    }
  `}
  ${props =>
    props.header &&
    `
    @media ${theme.md} {
      position: absolute;
      z-index: 300;
      top: 60px;
      left: 0;
      width: 784px;
      background-color: #fff;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
  `}
`;

const CityMenuStyle = ({ header, hide_other_countries, ...rest }) => {
  return (
    <Wrapper header={header} {...rest}>
      <CityMenu thumbnail={header} hide_other_countries={hide_other_countries} />
    </Wrapper>
  );
};

export default CityMenuStyle;
