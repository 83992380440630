import styled from 'styled-components';
import theme from '/style/theme';
import { srOnly } from '/style/mixin';

const Wrapper = styled.label`
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
  ${props =>
    props.required &&
    `
      &:after {
        content: '*';
        color: ${theme.trazy_og};
        font-size: 14px;
        margin-left: 5px;
      }
  `}
  ${props =>
    props.required_big &&
    `
      &:after {
        content: '*';
        color: ${theme.trazy_og};
        font-size: 20px;
        margin-left: 3px;
      }
  `}
  ${props =>
    props.requiredLeading &&
    `
      &:before {
        content: '*';
        display: inline;
        color: inherit;
        font-size: inherit;
        margin-right: 2px;
      }
  `}
  ${props =>
    props.verticalMiddle &&
    `
    @media ${theme.lg} {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -25px;
    }
  `}
  ${props =>
    props.horizontalLabelMobile &&
    `
    font-size: 14px;
    margin-bottom: 5px;
    width: 125px;
    line-height: 50px;
    white-space: nowrap;
    margin-bottom: 0;
    padding-bottom: 0;
    float: left;
    `}
  ${props =>
    props.multiline &&
  `
    line-height: 20px;
    margin-top: 5px; 
  `}
  ${props =>
    props.horizontalLabel &&
    `
      line-height: 16px;
      font-size: 14px;
      margin-bottom: 5px;
      @media ${theme.sm} {
        width: 125px;
        line-height: 50px;
        white-space: nowrap;
        margin-bottom: 0;
        padding-bottom: 0;
        float: left;
      }
  `}
  ${props =>
    props.small &&
    `
    @media ${theme.sm} {
      line-height: 36px;
    }
  `}
  ${props =>
    props.margin_bottom &&
    `
    margin-bottom: ${props.margin_bottom}px;
  `}
  ${props =>
    props.screenReaderText &&
    `
      ${srOnly}
  `}
`;

const Label = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
);

export default Label;
