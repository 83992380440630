import styled from "styled-components";
import FooterTitle from "./FooterTitle";
import FooterText from "./FooterText";
import { clearfix } from "/style/mixin";
import theme from "/style/theme";

const Wrapper = styled.div`
  margin-top: 30px;
  @media ${theme.sm} {
    margin-top: 40px;
    .footer-text {
      float: left;
      clear: left;
      margin-top: 3px;
      margin-right: 10px;
      width: 84px;
      white-space: nowrap;
    }
    .footer-title {
      margin-bottom: 16px;
    }
  }
`;
const Channels = styled.div`
  ${clearfix}
  margin-top: 15px;
  margin-bottom: 20px;
  svg {
    overflow: visible;
    width: 100%;
  }
  @media ${theme.sm} {
    margin-top: 0;
    margin-bottom: 0;
  }
  a {
    float: left;
    margin-left: 10px;
    // width: 48px;
    // height: 48px;
    &:first-child {
      margin-left: 0;
    }
    span {
      display: block;
    }
  }
  .facebook svg{
    width: 21px;
    height: 40px;
    @media ${theme.sm} {
      width: 12px;
      height: 23px;
    }
  }
  .insta svg{
    width: 39px;
    height: 39px;
    @media ${theme.sm} {
      width: 25px;
      height: 25px;
      margin-top: -1px;
    }
  }
  .youtube svg{
    width: 41px;
    height: 29px;
    margin-top: 5px;
    @media ${theme.sm} {
      width: 25px;
      height: 17px;
      margin-top: 3px;
    }
  }
`;
const Row = styled.div`
  @media ${theme.sm} {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
`;
const facebook = require("../../public/images/footer/footer-facebook.svg?include");
const insta = require("../../public/images/footer/footer-insta.svg?include");
const youtube = require("../../public/images/footer/footer-youtube.svg?include");

const StayConnected = () => {
  return (
    <Wrapper>
      <FooterTitle className="footer-title">STAY CONNECTED</FooterTitle>
      <Row>
        <FooterText className="footer-text">Trazy Korea</FooterText>
        <Channels>
          <a
            href="https://www.facebook.com/gotrazy"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="sr-only">Facebook</div>
            <span
              className="facebook"
              dangerouslySetInnerHTML={{
                __html: facebook,
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/trazy_korea/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="sr-only">Instagram</div>
            <span
              className="insta"
              dangerouslySetInnerHTML={{
                __html: insta,
              }}
            />
          </a>
          <a
            href="https://www.youtube.com/user/GoTrazy"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="sr-only">Youtube</div>
            <span
              className="youtube"
              dangerouslySetInnerHTML={{
                __html: youtube,
              }}
            />
          </a>
        </Channels>
      </Row>
      {/*<Row>
        <FooterText className="footer-text">Trazy Thailand</FooterText>
        <Channels>
          <a
            href="https://www.facebook.com/trazy.thailand"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="sr-only">Facebook</div>
            <span
              className="facebook"
              dangerouslySetInnerHTML={{
                __html: facebook,
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/trazy_thailand/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="sr-only">Instagram</div>
            <span
              className="insta"
              dangerouslySetInnerHTML={{
                __html: insta,
              }}
            />
          </a>
        </Channels>
      </Row>*/}
    </Wrapper>
  );
};

export default StayConnected;
