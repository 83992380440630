import styled from 'styled-components';
import Rate from 'react-rating';

interface WrapperProps {
  small?: boolean;
}
interface Props {
  small?: boolean;
  initialRating?: number;
  readonly?: boolean;
  style: object;
}
const Wrapper = styled.div<WrapperProps>`
  font-size: 0;
  > span {
    > span {
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  svg {
    width: 18px;
    height: 15px;
    ${props =>
      props.small &&
      `
      width: 12px;
      height: 10px;
    `}
  }
`;

const Rating: React.FC<Props> = ({ small, ...rest }) => {
  return (
    <Wrapper small={small}>
      <Rate
        emptySymbol={
          <span
            dangerouslySetInnerHTML={{
              __html: require('../../public/images/ui/ico-rating_off.svg?include'),
            }}
          />
        }
        fullSymbol={
          <span
            dangerouslySetInnerHTML={{
              __html: require('../../public/images/ui/ico-rating_on.svg?include'),
            }}
          />
        }
        {...rest}
      />
    </Wrapper>
  );
};

export default Rating;
