import styled, { keyframes } from 'styled-components';
import theme from '/style/theme';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(255,255,255,0.5); */
`;
const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;
const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  .spinner {
    animation: ${rotate} 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 40px;
    height: 40px;
    .path {
      stroke: ${theme.brand};
      stroke-linecap: round;
      animation: ${dash} 1.5s ease-in-out infinite;
    }
  }
`;

const Loading = ({ show, ...rest }) => {
  return show ? (
    <Wrapper {...rest}>
      <Spinner>
        <svg className="spinner" viewBox="0 0 50 50">
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="6"
          ></circle>
        </svg>
      </Spinner>
    </Wrapper>
  ) : null;
};

export default Loading;
