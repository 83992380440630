import styled from 'styled-components';
import { transition } from '/style/mixin';
import theme from '/style/theme';

const Wrapper = styled.label`
  position: relative;
  padding-left: 27px;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  input {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    clip: rect(0, 0, 0, 0);
  }
  .label {
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    font-weight: ${theme.normal};
    color: ${theme.mid_gy};
    line-height: 17px;
    ${transition()}
    ${props =>
      props.small &&
      `font-size: 12px;
      line-height: 1.2;`}
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 17px;
      height: 17px;
      border-radius: 100px;
      border: 1px solid ${theme.light_gy3};
      background-color: #ffffff;
      ${transition()}
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 4px;
      width: 9px;
      height: 9px;
      border-radius: 100px;
      background-color: #fff;
      ${transition()}
    }
  }
  // active state
  input:checked + .label {
    color: ${theme.trazy_og};
    &:before {
      border-color: ${theme.trazy_og};
    }
    &:after {
      background-color: ${theme.check_og};
      background-image: linear-gradient(
        to right,
        ${theme.light_og} 0%,
        ${theme.trazy_og} 100%
      );
    }
  }
  // disabled state
  input[readonly] + .label,
  input[disabled] + .label {
    color: ${theme.gy};
    &:before {
      border-color: ${theme.light_gy3};
      background-color: ${theme.light_gy1};
    }
    &:after {
      background-color: ${theme.light_gy1};
    }
  }
`;

const Radio = ({ children, ...rest }) => {
  return (
    <Wrapper>
      <input type="radio" {...rest} />
      <span className="label">{children}</span>
    </Wrapper>
  );
};

export default Radio;
