import styled from 'styled-components';
import { transition } from '/style/mixin';
import theme from '/style/theme';

const Wrapper = styled.label`
  position: relative;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  input {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    clip: rect(0, 0, 0, 0);
  }
  .label {
    height: 50px;
    padding: 15px 12px;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    font-weight: ${theme.normal};
    color: ${theme.gy};
    line-height: 1.2;
    background-color: #fff;
    border: solid 1px ${theme.line_gy};
    border-radius: 5px;
    ${transition()}
    &:hover {
      background-color: ${theme.light_gy2};
    }
    &:first-child {
      margin-left: 0;
    }
    ${props =>
      props.mint &&
      `
      background-color: ${theme.light_gy2};
    `}
    ${props =>
      props.small &&
      `
      padding: 10px;
      font-size: 15px;
      height: 40px;
    `}
    ${props =>
      props.medi &&
      `
      font-weight: ${theme.medi};
    `}
  }
  // active state
  input:checked + .label {
    color: #fff;
    border-color: ${theme.brand};
    background-color: ${theme.light_og};
    ${props =>
      props.mint &&
      `
      border-color: ${theme.trazy_mint};
      background-color: ${theme.light_mint3};
    `}
  }
`;

const RadioButton = ({ mint, small, medi, children, ...rest }) => {
  return (
    <Wrapper small={small} mint={mint} medi={medi}>
      <input type="radio" {...rest} />
      <span className="label">{children}</span>
    </Wrapper>
  );
};

export default RadioButton;
