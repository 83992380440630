import styled from 'styled-components';
import Link from 'next/link';
import FooterTitle from './FooterTitle';
import FooterText from './FooterText';
import theme from '/style/theme';
const Wrapper = styled.div`
  margin-top: 30px;
  @media ${theme.sm} {
    margin-top: 40px;
  }
  @media ${theme.lg} {
    margin-top: 0;
    position: absolute;
    top: 83px;
    right: 18px;
    width: 352px;
  }
  button {
    display: block;
    margin-top: 10px;
  }
  .participation-title {
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
    @media ${theme.xs_max} {
      a:hover,
      a:active {
        text-decoration: underline;
      }
    }
    @media ${theme.sm} {
      a {
        cursor: default;
        pointer-events: none;
      }
    }
  }
  .participation-text {
    display: none;
    @media ${theme.sm} {
      display: block;
    }
  }
`;

const Participation = () => {
  return (
    <Wrapper>
      <FooterTitle className="participation-title">
        <Link href="/affiliate">
          <a>Become an Affiliate: Join, Post &amp; Earn</a>
        </Link>
      </FooterTitle>
      <FooterText className="participation-text">
        <Link href="/affiliate">
          <a>
            Learn more <span className="sr-only">Affiliate</span>
          </a>
        </Link>
      </FooterText>
      <FooterTitle className="participation-title">
        <Link href="/point">
          <a>Sign Up &amp; Earn Trazy Points</a>
        </Link>
      </FooterTitle>
      <FooterText className="participation-text">
        <Link href="/point">
          <a>How to earn points</a>
        </Link>
      </FooterText>
    </Wrapper>
  );
};

export default Participation;
