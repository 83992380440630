import SweetAlert from 'react-bootstrap-sweetalert';
import theme from '/style/theme';
import { connect } from 'react-redux';
import actions from '/store/actions';
import PropTypes from 'prop-types';
import React from 'react';

const AlertSweetCustom = ({ data, changeSweetAlert, ...rest }) => {
  const closeSweetAlert = () => {
    changeSweetAlert({
      show: false,
    });
  };
  const onConfirmCustom = () => {
    data.onConfirm();
  };
  return (
    <SweetAlert
      title={data.title}
      type={data.type}
      show={data.show}
      onConfirm={data.onConfirm ? onConfirmCustom : closeSweetAlert}
      showCancel={data.showCancel}
      confirmBtnText={data.confirmBtnText}
      confirmBtnCssClass={
        data.confirmBtnCssClass ? data.confirmBtnCssClass : 'sweetalert-confirm'
      }
      cancelBtnText={data.cancelBtnText}
      cancelBtnCssClass={
        data.cancelBtnCssClass ? data.cancelBtnCssClass : 'sweetalert-cancel'
      }
      cancelBtnBsStyle={'default'}
      onCancel={closeSweetAlert}
      closeBtnStyle={data.closeBtnStyle}
      style={data.style}
      customClass={
        (data.customMode !== false ? 'custom-sweet-alert ' : '') +
        data.customClass
      }
      openAnim={data.openAnim}
      {...rest}
    >
      {data.content}
    </SweetAlert>
  );
};
AlertSweetCustom.propTypes = {
  changeSweetAlert: PropTypes.func,
  data: PropTypes.shape({
    show: PropTypes.bool,
    title: PropTypes.string,
  }),
};
AlertSweetCustom.defaultProps = {
  changeSweetAlert: () => {
    console.log('default');
  },
  data: {
    title: '',
    type: 'error',
    show: false,
  },
};

export default connect(state => state, actions)(AlertSweetCustom);
